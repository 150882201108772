import { message } from 'antd'
import { deleteDoc, doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { useTranslations } from 'contexts/Translation'

const useDeleteSnapshot = (postId, snapshotId) => {
  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(firestore, 'posts', postId, 'snapshots', snapshotId))
      message.success(t('Document successfully deleted'))
    } catch (error) {
      message.error(error.message)
    }
  }

  return handleDelete
}

export default useDeleteSnapshot
