import { Upload } from 'antd'
import styled from 'styled-components'

const { Dragger } = Upload

export const StyledDragger = styled(Dragger)`
  &.ant-upload.ant-upload-drag .ant-upload {
    padding: 0px;
  }

  overflow: hidden;
`
