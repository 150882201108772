import styled from 'styled-components'

const CollapseButtonWrapper = styled('div')`
  width: 24px;
  height: 24px;
  background-color: var(--menta-accent);
  border-radius: 0 0 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: ${(props) =>
    props?.collapse ? 'var(--ql-aside-collapsed-width)' : '280px'};
  cursor: pointer;
  transition: all 0.5s ease-out;
  &:hover {
    fill: var(--ql-color-white);
  }
`

export default CollapseButtonWrapper
