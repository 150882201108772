import { EditOutlined, EyeOutlined, SendOutlined } from '@ant-design/icons'
import { Dropdown, Menu, MenuItem } from '@qonsoll/react-design'
import { RemoveMenuItem } from 'components'
import { usePublishPost, useUnpublishPost } from 'domains/Post/hooks'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'
import { useMemo } from 'react'

const PostCardDropdown = (props) => {
  const {
    handleEdit,
    handleDelete,
    handlePreview,
    isDraft,
    title,
    postId,
    children
  } = props

  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  // [HELPER FUNCTIONS]
  const handlePublish = usePublishPost(postId, title)
  const handleUnpublish = useUnpublishPost(postId)
  const handleTogglePublish = () =>
    isDraft ? handlePublish() : handleUnpublish()

  // [COMPUTED PROPERTIES]
  const publishToggleText = useMemo(
    () => (isDraft ? t('Publish') : t('Unpublish')),
    [isDraft, t]
  )

  return (
    <Dropdown
      trigger={['contextMenu']}
      overlay={
        <Menu>
          <MenuItem
            key="publish/unpublish"
            icon={<SendOutlined />}
            onClick={(event) => {
              event.domEvent.stopPropagation()
              handleTogglePublish()
            }}
          >
            {publishToggleText}
          </MenuItem>
          <MenuItem
            key="preview"
            icon={<EyeOutlined />}
            onClick={(event) => {
              event.domEvent.stopPropagation()
              handlePreview()
            }}
          >
            {t('Preview')}
          </MenuItem>
          <MenuItem
            key="edit"
            icon={<EditOutlined />}
            onClick={(event) => {
              event.domEvent.stopPropagation()
              handleEdit()
            }}
          >
            {t('Edit')}
          </MenuItem>
          <RemoveMenuItem
            onClick={(event) => {
              event.domEvent.stopPropagation()
              handleDelete()
            }}
          />
        </Menu>
      }
    >
      {children}
    </Dropdown>
  )
}

PostCardDropdown.propTypes = {
  children: PropTypes.node.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handlePreview: PropTypes.func.isRequired,
  isDraft: PropTypes.bool,
  postId: PropTypes.string,
  title: PropTypes.string
}

export default PostCardDropdown
