import { useEffect, useMemo, useState } from 'react'

import { useTranslations } from 'contexts/Translation'
import moment from 'moment'

const useCalculateUpdateDifference = (unixSeconds) => {
  const [updateText, setText] = useState()

  const { t } = useTranslations()

  useEffect(() => {
    const calculate = (_seconds) => {
      const duration = moment.duration(moment().diff(moment.unix(_seconds)))
      const seconds = duration.asSeconds().toFixed(0)

      if (seconds < 10) return t('just now')
      else if (seconds < 60) return seconds + ' ' + t('seconds ago')
      else if (seconds < 120) return t('a minute ago')
      else if (seconds < 3600)
        return Number(seconds / 60).toFixed(0) + ' ' + t('minutes ago')
      else return t('more than an hour ago')
    }

    if (unixSeconds) {
      setText(calculate(unixSeconds))
      var timer = setInterval(() => setText(calculate(unixSeconds)), [10000])
    }

    return () => clearInterval(timer)
  }, [unixSeconds, t])

  const text = useMemo(
    () => (updateText ? `${t('Last edited')} ${updateText}` : ''),
    [t, updateText]
  )

  return text
}

export default useCalculateUpdateDifference
