import PropTypes from 'prop-types'
import { Spin } from 'antd'
import { Box } from '@qonsoll/react-design'
import { memo } from 'react'

const LoadingBox = (props) => {
  const { loading, spinnerProps, children, ...rest } = props

  if (loading)
    return (
      <Box width="100%" display="flex" justifyContent="center" {...rest}>
        <Spin {...spinnerProps} />
      </Box>
    )

  return children
}

LoadingBox.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.bool,
  spinnerProps: PropTypes.object
}

export default memo(LoadingBox)
