import { useCallback, useMemo, useState } from 'react'

// Default state with auto writing to local storage
const useStateWithStorage = (initialValue, fieldName) => {
  // [INITIALIZATION]
  const initialState = useMemo(() => {
    const json = fieldName && localStorage?.getItem?.(fieldName)
    return json ? JSON.parse(json) : initialValue
  }, [fieldName, initialValue])

  // [COMPONENT STATE]
  const [state, setState] = useState(initialState)

  // [FUNCTIONS]
  const handleChange = useCallback(
    (value) => {
      if (typeof value === 'function') {
        setState((prev) => {
          const newValue = value(prev)
          if (fieldName)
            localStorage?.setItem?.(fieldName, JSON.stringify(newValue))
          return newValue
        })
      } else {
        setState(value)
        if (fieldName) localStorage?.setItem?.(fieldName, JSON.stringify(value))
      }
    },
    [fieldName]
  )

  return [state, handleChange]
}

export default useStateWithStorage
