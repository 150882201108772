import { useAuthState } from 'react-firebase-hooks/auth'
import firebase from 'firebase/compat/app'

const useGetUserName = () => {
  const [user] = useAuthState(firebase.auth())

  return user.displayName
}

export default useGetUserName
