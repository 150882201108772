import { getDocument } from 'services/firestore'
import { createDocument } from 'services/firestore'
import moment from 'moment'
import { message } from 'antd'
import { useTranslations } from 'contexts/Translation'

const useCreateSnapshot = (postId) => {
  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  const create = async () => {
    try {
      const post = await getDocument('posts', postId)
      const data = {
        title: post.title,
        metaDescription: post.metaDescription,
        description: post.description,
        author: post.author,
        categoryId: post.categoryId,
        previewImageUrl: post.previewImageUrl,
        descriptionPreview: post.descriptionPreview
      }
      const name = `Snapshot ${moment().format('YYYY-MMM-DD, HH:mm:ss')}`
      await createDocument(`posts/${postId}/snapshots`, { post: data, name })

      message.success(t('Snapshot successfully created'))
    } catch (error) {
      message.error(error.message)
    }
  }

  return create
}

export default useCreateSnapshot
