import { PageWrapper, Container, Row, Col } from '@qonsoll/react-design'
import { TagAdvancedForm } from 'domains/Tag/components'
import { useTranslations } from 'contexts/Translation'
import { HeaderBreadcrumbs } from 'components'
import { useHistory } from 'react-router-dom'

export default function TagCreate() {
  const { t } = useTranslations()
  const history = useHistory()
  const headingProps = {
    title: t('Create tag'),
    titleSize: 2
  }

  return (
    <Container bounded>
      <PageWrapper
        onBack={() => history?.goBack()}
        breadcrumbs={<HeaderBreadcrumbs />}
        headingProps={headingProps}
      >
        <Row marginTop="8px">
          <Col>
            <TagAdvancedForm />
          </Col>
        </Row>
      </PageWrapper>
    </Container>
  )
}
