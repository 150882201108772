import { Col, Row } from '@qonsoll/react-design'
import PostSimpleView from '../PostSimpleView'
import { Empty, AddItemCard } from 'components'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useCallback, useMemo, useState } from 'react'
import { useTranslations } from 'contexts/Translation'
import { Grid } from 'antd'
import { SelectableCardStyled } from './PostList.styled'
import { useCreatePost } from '../../hooks'

const { useBreakpoint } = Grid

const PostList = (props) => {
  const { listView, hideAddCard, posts, isListWithCreate } = props

  // [ADDITIONAL HOOKS]
  const history = useHistory()
  const { t } = useTranslations()
  const { xs } = useBreakpoint()
  const createPost = useCreatePost()

  // [COMPONENT STATE]
  const [selectedItem, setSelectedItem] = useState(null)

  // [COMPUTED PROPERTIES]
  const isHorizontalLayout = useMemo(() => listView && !xs, [listView, xs])

  // [FUNCTIONS]
  const handleEmptySpaceClick = () => setSelectedItem(null)
  const handleCreate = useCallback(async () => {
    const id = await createPost()
    id && history.push(`/posts/${id}/edit`)
  }, [createPost, history])

  return (
    <Row onClick={handleEmptySpaceClick} ml={-16} mr={-16}>
      {posts?.length > 0 ? (
        <>
          {!hideAddCard && (
            <AddItemCard
              message={t('Add post')}
              onClickAction={handleCreate}
              cardWidth={isHorizontalLayout ? [12] : [12, 6, 6, 6, 4]}
            />
          )}
          {posts?.map((post, index) => (
            <Col
              key={post?._id || index}
              cw={isHorizontalLayout ? 12 : [12, 6, 6, 6, 4]}
            >
              <SelectableCardStyled
                mb={32}
                _id={post?._id}
                isDraft={post.isDraft}
                selectedItem={selectedItem}
                isHorizontalLayout={isHorizontalLayout}
                setSelectedItem={setSelectedItem}
              >
                <PostSimpleView
                  isHorizontalLayout={isHorizontalLayout}
                  post={post}
                />
              </SelectableCardStyled>
            </Col>
          ))}
        </>
      ) : (
        <Empty
          message={t('No posts')}
          showImage={!isListWithCreate}
          onCreateButtonClick={handleCreate}
        />
      )}
    </Row>
  )
}

PostList.propTypes = {
  posts: PropTypes.array,
  onCreateButtonClick: PropTypes.func,
  isListWithCreate: PropTypes.bool,
  hideAddCard: PropTypes.bool,
  listView: PropTypes.bool
}

export default PostList
