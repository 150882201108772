import { message } from 'antd'
import { uploadFile } from '../../../../../../../services/storage'

const useUploadPreviewImage = () => {
  const handleUpload = async (file, postId) => {
    try {
      const { uid, name, size } = file || {}

      const mb = size && (size / 1024 / 1024).toFixed(0)

      if (mb && mb > 5)
        throw new Error(
          'To big file. Files smaller than 5 megabytes are allowed.'
        )

      const path = `posts/${postId}/previewImages/${uid}_${name}`
      const [url] = await uploadFile(file, path)
      return url
    } catch (error) {
      message.error(error.message)
      return null
    }
  }

  return handleUpload
}

export default useUploadPreviewImage
