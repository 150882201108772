import { useUser } from 'domains/User/context'
import { useCallback, useEffect, useMemo } from 'react'
import moment from 'moment'
import { getDatabase, ref, set, remove } from 'firebase/database'
import { useListenRDBOnlineUsers } from 'domains/Post/hooks'

const usePostFormOnlineUsers = (postId) => {
  // [ADDITIONAL HOOKS]
  const user = useUser()

  // [COMPUTED PROPERTIES]
  const userId = useMemo(() => user?.id, [user.id])
  const onlineUsersRef = useMemo(
    () => (postId ? `posts/${postId}/onlineUsers` : null),
    [postId]
  )

  // [DATA FETCH]
  const onlineUsers = useListenRDBOnlineUsers(onlineUsersRef)

  // [FUNCTIONS]
  const updateOnlineUsers = useCallback(
    (userId, data) => {
      if (userId && data) {
        const db = getDatabase()
        const additionalFields = { lastActive: moment().toISOString() }
        const path = `${onlineUsersRef}/${userId}`
        set(ref(db, path), { ...additionalFields, ...data })
      }
    },
    [onlineUsersRef]
  )
  const removeUserOnlineStatus = useCallback(
    (userId) => {
      if (userId) {
        const db = getDatabase()
        remove(ref(db, `${onlineUsersRef}/${userId}`))
      }
    },
    [onlineUsersRef]
  )
  const activateUser = useCallback(() => {
    const { avatarUrl, id: userId, displayName } = user
    if (userId) {
      const data = { avatarUrl, userId, name: displayName }
      updateOnlineUsers(userId, data)
    }
  }, [user, updateOnlineUsers])
  const deactivateUser = useCallback(
    (userId) => userId && removeUserOnlineStatus(userId),
    [removeUserOnlineStatus]
  )
  const updateActiveUsers = useCallback(
    (onlineUsers) => {
      const deactivations = Object.values(onlineUsers).filter(
        ({ lastActive }) => {
          const minutes = moment
            .duration(moment().diff(moment(lastActive)))
            .asMinutes()

          return minutes && minutes > 5
        }
      )
      deactivations.forEach(({ userId }) => deactivateUser(userId))
    },
    [deactivateUser]
  )

  // [LIFECYCLE]
  useEffect(() => activateUser(), [activateUser])
  useEffect(
    () => onlineUsers && updateActiveUsers(onlineUsers),
    [onlineUsers, updateActiveUsers]
  )
  useEffect(() => {
    return () => {
      if (userId) {
        // console.log('deactivate user: ', userId)
        deactivateUser(userId)
      }
    }
  }, [userId, deactivateUser])

  return onlineUsers
}

export default usePostFormOnlineUsers
