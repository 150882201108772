import PropTypes from 'prop-types'
import { Drawer } from 'antd'
import SnapshotsList from '../SnapshotsList'
import { useSnapshotService } from '../../hooks'
import { useTranslations } from 'contexts/Translation'

const SnapshotsAside = (props) => {
  const { toggle, visible, postId } = props

  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()
  useSnapshotService(postId)

  return (
    <>
      {visible && (
        <Drawer
          title={t('Snapshots')}
          placement="right"
          closable
          onClose={toggle}
          visible={visible}
          getContainer={false}
        >
          <SnapshotsList postId={postId} />
        </Drawer>
      )}
    </>
  )
}

SnapshotsAside.propTypes = {
  postId: PropTypes.string,
  toggle: PropTypes.func,
  visible: PropTypes.bool
}

export default SnapshotsAside
