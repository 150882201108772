import { Redirect, Route, Switch } from 'react-router-dom'
import { PostsAll, PostShow, PostCreate, PostEdit } from './Post'
import { TagsAll, TagShow, TagCreate, TagEdit } from './Tag'
import {
  CategoriesAll,
  CategoryShow,
  CategoryCreate,
  CategoryEdit
} from './Category'
import TranslationsRoute from './Translations'

import { BoilerplateLayout } from 'components'
import Dashboard from './Dashboard/Dashboard'
import { UserShow } from './User'
import PATHS from '../paths'

const {
  DASHBOARD,
  USER_SHOW,
  POSTS_ALL,
  POST_SHOW,
  POST_CREATE,
  POST_EDIT,
  TAGS_ALL,
  TAG_SHOW,
  TAG_CREATE,
  TAG_EDIT,
  CATEGORIES_ALL,
  CATEGORY_SHOW,
  CATEGORY_CREATE,
  CATEGORY_EDIT,
  TRANSLATIONS
} = PATHS.AUTHENTICATED

const routes = [
  { key: 'DASHBOARD', path: DASHBOARD, component: Dashboard, exact: true },
  { key: 'USER_SHOW', path: USER_SHOW, component: UserShow, exact: true },
  { key: 'POSTS_ALL', path: POSTS_ALL, component: PostsAll, exact: true },
  { key: 'POST_SHOW', path: POST_SHOW, component: PostShow, exact: true },
  { key: 'POST_CREATE', path: POST_CREATE, component: PostCreate, exact: true },
  { key: 'POST_EDIT', path: POST_EDIT, component: PostEdit, exact: true },
  { key: 'TAGS_ALL', path: TAGS_ALL, component: TagsAll, exact: true },
  { key: 'TAG_SHOW', path: TAG_SHOW, component: TagShow, exact: true },
  { key: 'TAG_CREATE', path: TAG_CREATE, component: TagCreate, exact: true },
  { key: 'TAG_EDIT', path: TAG_EDIT, component: TagEdit, exact: true },
  {
    key: 'CATEGORIES_ALL',
    path: CATEGORIES_ALL,
    component: CategoriesAll,
    exact: true
  },
  {
    key: 'CATEGORY_SHOW',
    path: CATEGORY_SHOW,
    component: CategoryShow,
    exact: true
  },
  {
    key: 'CATEGORY_CREATE',
    path: CATEGORY_CREATE,
    component: CategoryCreate,
    exact: true
  },
  {
    key: 'CATEGORY_EDIT',
    path: CATEGORY_EDIT,
    component: CategoryEdit,
    exact: true
  },
  {
    key: 'TRANSLATIONS',
    path: TRANSLATIONS,
    component: TranslationsRoute,
    exact: true
  }
]

const App = () => {
  return (
    <BoilerplateLayout>
      <Switch>
        {routes.map((routeProps) => (
          <Route key={routeProps.key} {...routeProps} />
        ))}
        <Redirect to={PATHS.SERVICE.NOT_FOUND} />
      </Switch>
    </BoilerplateLayout>
  )
}

export default App
