import {
  Container,
  Spin,
  Img,
  Row,
  Col,
  Box,
  Text
} from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import moment from 'moment'
import { RichTextEditor } from 'components'
import { imageStyles, StyledTitle } from './PostAdvancedView.styled'

const PostAdvancedView = (props) => {
  const {
    previewImageUrl,
    loading,
    author,
    publishingDate,
    title,
    description
  } = props

  const publishDateComputed = useMemo(
    () =>
      publishingDate ? moment(publishingDate).format('DD MMM YYYY', []) : null,
    [publishingDate]
  )

  return (
    <Container>
      {loading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="80vh"
        >
          <Spin h="center" />
        </Box>
      ) : (
        <Container mb={32}>
          <Row noGutters h="center">
            <Col cw={[12, 9]}>
              <StyledTitle textAlign="center" my={32}>
                {title}
              </StyledTitle>
            </Col>
          </Row>
          <Box>
            <Img
              width="100%"
              maxHeight={[180, 268, 368, 568]}
              style={imageStyles}
              src={previewImageUrl}
            />
          </Box>
          <Box py={32}>
            <RichTextEditor defaultValue={description} readOnly />
            <Row noGutters h="between" mt={32}>
              <Col cw="auto">
                <Text variant="caption1" fontSize="14px">
                  {author}
                </Text>
              </Col>
              <Col cw="auto">
                <Text variant="caption1" fontSize="14px">
                  {publishDateComputed}
                </Text>
              </Col>
            </Row>
          </Box>
        </Container>
      )}
    </Container>
  )
}

PostAdvancedView.propTypes = {
  author: PropTypes.string,
  description: PropTypes.string,
  loading: PropTypes.bool,
  previewImageUrl: PropTypes.string,
  publishingDate: PropTypes.string,
  title: PropTypes.string
}

export default PostAdvancedView
