import { useEffect, useState } from 'react'
import { onValue, ref, getDatabase } from 'firebase/database'

const useListenRDBOnlineUsers = (path) => {
  const [data, setData] = useState(null)

  useEffect(() => {
    let unmountRef = null
    if (path) {
      const db = getDatabase()
      const dbRef = ref(db, path)
      unmountRef = onValue(dbRef, (snapshot) => setData(snapshot.val()))
    }
    return () => {
      // console.log('Detach online users listener', unmountRef)
      unmountRef?.()
    }
  }, [path])

  return data
}

export default useListenRDBOnlineUsers
