import { Box, Col, Container, Row, Text } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { PostCardDropdown } from './components'
import { useHistory } from 'react-router-dom'
import { memo, useMemo } from 'react'
import moment from 'moment'
import {
  StyledDescription,
  StyledTitle,
  StyledImg
} from './PostSimpleView.styled'
import LazyLoad from 'react-lazy-load'
import { useTranslations } from 'contexts/Translation'
import { useDoubleClick } from '../../../../hooks'
import { useDeletePost } from 'domains/Post/hooks'
import { LoadingBox } from 'components'

const blockSelectStyles = { userSelect: 'none', cursor: 'pointer' }

const PostSimpleView = (props) => {
  const { post, isHorizontalLayout } = props
  const {
    previewImageUrl,
    author,
    publishingDate,
    title,
    isDraft,
    descriptionPreview
  } = post

  // [ADDITIONAL HOOKS]
  const history = useHistory()
  const { t } = useTranslations()
  const [handleDelete, isDeleting] = useDeletePost(post._id)

  // [FUNCTIONS]
  const handleEdit = () => history.push(`/posts/${post?._id}/edit`)
  const handleOpen = () => history.push(`/posts/${post?._id}`)
  const handleDoubleClick = useDoubleClick(handleEdit)

  // [COMPUTED PROPERTIES]
  const publishDateComputed = useMemo(
    () =>
      publishingDate ? moment(publishingDate).format('DD MMM YYYY', []) : '',
    [publishingDate]
  )
  const imageColResponsiveStyles = useMemo(
    () =>
      isHorizontalLayout
        ? { cw: 4, height: '100%', order: 2, pr: 0, pl: 4 }
        : { cw: 12, height: 'unset', order: 1, pr: 0, pl: 0 },
    [isHorizontalLayout]
  )
  const textColResponsiveStyles = useMemo(
    () =>
      isHorizontalLayout
        ? { cw: 8, order: 1, px: 0 }
        : { cw: 12, order: 2, px: 0 },
    [isHorizontalLayout]
  )

  return (
    <LoadingBox loading={isDeleting} alignItems="center" height="inherit">
      <Container
        bg="white"
        cursor="pointer"
        style={blockSelectStyles}
        onClick={handleDoubleClick}
        height="inherit"
      >
        <PostCardDropdown
          postId={post._id}
          isDraft={isDraft}
          title={title}
          handlePreview={handleOpen}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
        >
          <Row height="inherit">
            <Col {...imageColResponsiveStyles} v="between" h="between">
              <LazyLoad height={isHorizontalLayout ? '100%' : '242px'} once>
                <StyledImg
                  isHorizontalLayout={isHorizontalLayout}
                  src={previewImageUrl}
                />
              </LazyLoad>
            </Col>
            <Col {...textColResponsiveStyles}>
              <Box p="32px 16px">
                <Row noGutters h="between">
                  <Col>
                    <Text variant="caption1" fontSize="14px">
                      {author}
                    </Text>
                  </Col>
                  {!isDraft && (
                    <Col cw="auto">
                      <Text variant="caption1" fontSize="14px">
                        {publishDateComputed}
                      </Text>
                    </Col>
                  )}
                </Row>
                <StyledTitle
                  isHorizontalLayout={isHorizontalLayout}
                  my="16px"
                  level={3}
                >
                  {title || t('Untitled')}
                </StyledTitle>
                <StyledDescription isHorizontalLayout={isHorizontalLayout}>
                  {descriptionPreview}
                </StyledDescription>
              </Box>
            </Col>
          </Row>
        </PostCardDropdown>
      </Container>
    </LoadingBox>
  )
}

PostSimpleView.propTypes = {
  isHorizontalLayout: PropTypes.bool,
  post: PropTypes.object
}

export default memo(PostSimpleView)
