import {
  PageWrapper,
  Container,
  Row,
  Col,
  Spin,
  Button
} from '@qonsoll/react-design'
import { PostList, PostSortMenu, PostFilter } from 'domains/Post/components'
import { useTranslations } from 'contexts/Translation'
import { HeaderBreadcrumbs } from 'components'
import { usePosts } from 'domains/Post/hooks'
import { useHistory } from 'react-router-dom'
import {
  OrderedListOutlined,
  AppstoreOutlined,
  FilterOutlined,
  FilterFilled,
  MenuOutlined
} from '@ant-design/icons'
import { useMemo } from 'react'
import { Popover } from 'antd'
import { useStateWithStorage } from 'hooks'

export default function PostsAll() {
  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()
  const history = useHistory()

  // [COMPONENT STATE]
  const [listView, setListView] = useStateWithStorage(true, 'postsListView')
  const [filterData, setFilterData] = useStateWithStorage({}, 'postsFilterData')
  const [filterVisibility, setFilterVisibility] = useStateWithStorage(
    false,
    'postsFilterVisibility'
  )

  // [DATA FETCH]
  const [posts, loading] = usePosts(filterData)

  const headingProps = { title: t('Posts'), titleSize: 2, textAlign: 'left' }
  const filterButtonIcon = useMemo(
    () => (filterVisibility ? <FilterFilled /> : <FilterOutlined />),
    [filterVisibility]
  )

  // [COMPUTED PROPERTIES]
  const postsResponsiveStyles = useMemo(
    () =>
      filterVisibility
        ? { cw: [12, 12, 12, 12, 9], pl: [0, 0, 0, 0, 3], pr: 0 }
        : { cw: 12, px: 0 },
    [filterVisibility]
  )
  const filterResponsiveStyles = useMemo(
    () => ({ cw: [12, 12, 12, 12, 3], pr: [0, 0, 0, 0, 3], pl: 0 }),
    []
  )

  // [FUNCTIONS]
  const handleToggleFilter = () => setFilterVisibility((prev) => !prev)

  return (
    <Container bounded>
      <PageWrapper
        alignMiddle
        action={
          <>
            <Button
              mr={2}
              onClick={handleToggleFilter}
              icon={filterButtonIcon}
            />
            <Popover
              placement="bottomRight"
              trigger="click"
              content={
                <PostSortMenu
                  filteredData={filterData}
                  setFilteredData={setFilterData}
                />
              }
            >
              <Button mr={2} icon={<OrderedListOutlined />} />
            </Popover>
            <Button
              onClick={() => setListView(!listView)}
              icon={listView ? <AppstoreOutlined /> : <MenuOutlined />}
            />
          </>
        }
        onBack={() => history?.goBack()}
        breadcrumbs={<HeaderBreadcrumbs />}
        headingProps={headingProps}
      >
        <Row>
          {filterVisibility && (
            <Col {...filterResponsiveStyles} mb={3}>
              <PostFilter
                filterVisibility={filterVisibility}
                filterData={filterData}
                setFilterData={setFilterData}
              />
            </Col>
          )}
          <Col {...postsResponsiveStyles}>
            {loading ? (
              <Spin />
            ) : (
              <PostList listView={listView} posts={posts} />
            )}
          </Col>
        </Row>
      </PageWrapper>
    </Container>
  )
}
