import { Icon } from '@qonsoll/icons'

import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'
import { useHistory, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { StyledMenu, StyledMenuItem } from './Menu.styled'
import pluralize from 'pluralize'
import { Box, Col, Divider, Row } from '@qonsoll/react-design'
import LanguageSelect from '../LanguageSelect'

const MainMenu = ({ collapse }) => {
  const { t } = useTranslations()
  const history = useHistory()
  const location = useLocation()

  const selectedKey = useMemo(() => {
    const domain = location?.pathname?.split('/')?.[1]
    return pluralize.singular(domain)
  }, [location])

  const menuItems = useMemo(
    () => [
      {
        key: 'post',
        value: 'POSTS',
        icon: 'FileFilled',
        text: t('Posts'),
        onClick: () => history.push('/posts')
      },
      {
        key: 'category',
        value: 'CATEGORIES',
        icon: 'CategoryFilled',
        text: t('Categories'),
        onClick: () => history.push('/categories')
      },
      {
        key: 'tag',
        value: 'TAGS',
        icon: 'MinusFilled',
        text: t('Tags'),
        onClick: () => history.push('/tags')
      }
    ],
    [t, history]
  )

  return (
    <>
      <Row my={16} height="40px">
        <Col px={2}>
          <LanguageSelect isMenuCollapsed={collapse} />
        </Col>
      </Row>

      <StyledMenu
        theme="dark"
        border="unset"
        mode="inline"
        inlineCollapsed={collapse}
        selectedKeys={selectedKey}
      >
        <StyledMenuItem
          onClick={() => history.push('/translations')}
          key="translation"
          icon={
            <Icon
              name="TranslationFilled"
              fill={
                'translation' === selectedKey
                  ? 'var(--menu-dark-selected-item-icon-color)'
                  : 'var(--ql-color-white-t-lighten1)'
              }
            />
          }
        >
          {!collapse && t('Translations')}
        </StyledMenuItem>

        <Box mx={!collapse ? -24 : 0}>
          <Divider my={3} borderColor="var(--ql-color-white-t-lighten4)" />
        </Box>

        {menuItems.map((item) => (
          <StyledMenuItem
            onClick={item.onClick}
            key={item.key}
            icon={
              <Icon
                name={item.icon}
                fill={
                  item.key === selectedKey
                    ? 'var(--menu-dark-selected-item-icon-color)'
                    : 'var(--ql-color-white-t-lighten1)'
                }
              />
            }
          >
            {!collapse && item.text}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </>
  )
}

MainMenu.propTypes = {
  collapse: PropTypes.bool
}

export default MainMenu
