import { message } from 'antd'
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore'
import { deleteDocument, updateDocument } from 'services/firestore'
import firebase from 'firebase/compat/app'

const useCategoryDelete = (categoryId) => {
  const [postsWithCategory] = useCollectionDataOnce(
    categoryId &&
      firebase
        .firestore()
        .collection('posts')
        .where('categoryId', '==', categoryId)
  )

  const handleDelete = async () => {
    try {
      await deleteDocument('categories', categoryId)
      // Updating references
      if (postsWithCategory?.length) {
        await Promise.all(
          postsWithCategory.map(({ _id }) =>
            updateDocument('posts', _id, { categoryId: null })
          )
        )
      }

      message.success('Category successfully deleted')
    } catch (error) {
      message.error(error.message)
    }
  }

  return handleDelete
}

export default useCategoryDelete
