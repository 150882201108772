import { Divider, Form } from 'antd'
import { useEffect, Fragment, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@qonsoll/react-design'
import { useTranslations } from 'contexts/Translation'
import { RichTextEditor } from 'components'
import {
  PostFormHeader,
  PostFormImage,
  PostServiceFieldsForm
} from './components'
import { usePostFormState } from './hooks'
import { usePublishPost } from 'domains/Post/hooks'
import { useStateWithStorage } from '../../../../hooks'

const PostAdvancedForm = (props) => {
  const { initialData, toggleRightAside } = props
  const { post } = initialData || {}

  // [ADDITIONAL HOOKS]
  const handlePublish = usePublishPost(post?._id, post?.title)
  const editorRef = useRef(null)
  const { t } = useTranslations()

  // [COMPONENT STATE HOOKS]
  const [additionalFieldsVisible, setAdditionalFieldsVisible] =
    useStateWithStorage(false, 'postAdditionalFieldsVisible')
  const [formData, initFormData, handlers, isInitialized] = usePostFormState(
    post?._id
  )

  // [COMPONENT LIFECYCLE]
  useEffect(() => {
    if (initialData) {
      const { post, tags } = initialData
      const {
        title,
        previewImageUrl,
        metaDescription,
        description,
        author,
        categoryId
      } = post || {}

      const initialValues = {
        tags: tags || [],
        categoryId: categoryId || null,
        title: title || '',
        previewImageUrl: previewImageUrl || null,
        description: description || '',
        metaDescription: metaDescription || '',
        author: author || ''
      }
      initFormData(initialValues)
    }
  }, [initialData, initFormData])

  // [COMPUTED PROPERTIES]
  const storyPlaceholder = useMemo(() => t('Your story') + '...', [t])

  const toggleAdditionalFields = () => {
    setAdditionalFieldsVisible((prev) => !prev)
  }

  return (
    isInitialized && (
      <Fragment>
        <PostFormHeader
          author={formData?.author}
          postId={post?._id}
          lastUpdateDate={post?._updatedAt}
          isDraft={post?.isDraft}
          onPublish={handlePublish}
          onUploadPreviewImage={handlers?.onUploadPreviewImage}
          toggleAdditionalFields={toggleAdditionalFields}
          toggleRightAside={toggleRightAside}
          additionalFieldsVisible={additionalFieldsVisible}
        />

        <Box my={3}>
          <PostFormImage
            postId={post?._id}
            url={formData?.previewImageUrl}
            onUploadPreviewImage={handlers?.onUploadPreviewImage}
          />
        </Box>

        <Form>
          <PostServiceFieldsForm
            additionalFieldsVisible={additionalFieldsVisible}
            editorRef={editorRef}
            tags={formData?.tags}
            title={formData?.title}
            category={formData?.categoryId}
            metaDescription={formData?.metaDescription}
            author={formData?.author}
            setTags={handlers?.onTagsChange}
            setCategory={handlers?.onCategoryChange}
            onTitleChange={handlers?.onTitleChange}
            onMetaDescriptionChange={handlers?.onMetaDescriptionChange}
            onAuthorChange={handlers?.onAuthorChange}
            onUrlPaste={handlers?.onUrlPaste}
          />

          <Box my={3}>
            <Divider />
          </Box>

          <RichTextEditor
            postId={post?._id}
            onChange={handlers?.onDescriptionChange}
            defaultValue={formData?.description}
            textEditorRef={editorRef}
            placeholder={storyPlaceholder}
          />
        </Form>
      </Fragment>
    )
  )
}

PostAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  toggleRightAside: PropTypes.func
}

export default PostAdvancedForm
