import { useCallback, useEffect, useRef } from 'react'

function useDoubleClick(callback) {
  const ref = useRef(null)

  const fireDoubleClick = useCallback(() => callback?.(), [callback])
  const clearRef = useCallback(() => {
    clearTimeout(ref.current)
    clearTimeout(ref)
    ref.current = null
  }, [])

  const handleClick = useCallback(
    (e, timeLimit = 300) => {
      if (ref.current) {
        clearRef()
        fireDoubleClick()
      }
      ref.current = setTimeout(clearRef, timeLimit)
    },
    [clearRef, fireDoubleClick]
  )

  useEffect(() => {
    return () => clearTimeout(ref.current)
  }, [])

  return handleClick
}

export default useDoubleClick
