import ImageTool from '@editorjs/image'
import { nanoid } from 'nanoid'
import { uploadFile } from 'services/storage'

const getAdditionalTools = (id) => {
  const path = id ? `posts/${id}/editor-images/` : 'editor-images/'

  return {
    image: {
      class: ImageTool,
      config: {
        uploader: {
          async uploadByFile(file) {
            const [downloadURL] = await uploadFile(file, `${path}${file.name}`)
            return { success: 1, file: { url: downloadURL } }
          },
          async uploadByUrl(url) {
            const blob = await fetch(url).then((r) => r.blob())
            const fileName = nanoid()
            const [downloadURL] = await uploadFile(blob, `${path}${fileName}`)
            return { success: 1, file: { url: downloadURL } }
          }
        }
      }
    }
  }
}

export default getAdditionalTools
