import { TagListWithCreate } from 'domains/Tag/components'
import { CategorySelect } from 'domains/Category/components'
import PropTypes from 'prop-types'

import PostFormItem from '../PostFormItem'
import { StyledTitle, StyledInput } from './PostServiceFieldsForm.styled'
import { Fragment } from 'react'
import { useTranslations } from 'contexts/Translation'
import { Title } from '@qonsoll/react-design'
import { message } from 'antd'
import { useGetUrlsMetaData } from 'domains/Post/hooks'

const PostServiceFieldsForm = (props) => {
  const {
    // Fields
    tags,
    category,
    title,
    metaDescription,
    author,
    // Handlers
    setCategory,
    onTitleChange,
    setTags,
    onMetaDescriptionChange,
    onAuthorChange,
    onUrlPaste,
    // Other
    editorRef,
    additionalFieldsVisible
  } = props

  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  // [COMPUTED PROPERTIES]
  const editor = editorRef.current?._editorJS

  // [FUNCTIONS]
  const getMeta = useGetUrlsMetaData()
  const handleFocusNext = (target) => target?.focus()
  const handleKeyDown = (e, target) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      handleFocusNext(target)
    }
  }
  const handleUrlPaste = async (urls = []) => {
    try {
      const urlsMeta = await getMeta(urls)
      await onUrlPaste(urlsMeta, editor)
      message.success(t('Link successfully parsed'))
    } catch (error) {
      message.error(
        `${'Problems with fetching data from url'}. ${t('Try other link')}.`
      )
    }
  }

  return (
    <Fragment>
      <Title level={2}>
        <StyledTitle
          placeholder={t('Title')}
          autoFocus
          value={title}
          onKeyDown={(e) => handleKeyDown(e, editor)}
          onUrlPaste={handleUrlPaste}
          onChange={onTitleChange}
        />
      </Title>

      {additionalFieldsVisible && (
        <>
          <PostFormItem icon="MinusFilled" tipText={t('Tags')}>
            <TagListWithCreate
              placeholder={t('Empty')}
              tags={tags}
              showTitle
              setTags={setTags}
            />
          </PostFormItem>

          <PostFormItem icon="CategoryFilled" tipText={t('Category')}>
            <CategorySelect
              placeholder={t('Empty')}
              category={category}
              setCategory={setCategory}
            />
          </PostFormItem>

          <PostFormItem icon="CompressFilled" tipText={t('Meta description')}>
            <StyledInput
              placeholder={t('Empty')}
              value={metaDescription}
              onKeyDown={(e) => handleKeyDown(e, editorRef.current?._editorJS)}
              onChange={onMetaDescriptionChange}
            />
          </PostFormItem>

          <PostFormItem icon="PatientFilled" tipText={t('Author')}>
            <StyledInput
              placeholder={t('Empty')}
              value={author}
              // onKeyDown={(e) => handleKeyDown(e, editorRef.current?._editorJS)}
              onChange={onAuthorChange}
            />
          </PostFormItem>
        </>
      )}
    </Fragment>
  )
}

PostServiceFieldsForm.propTypes = {
  additionalFieldsVisible: PropTypes.bool,
  author: PropTypes.string,
  category: PropTypes.any,
  editorRef: PropTypes.shape({
    current: PropTypes.shape({
      _editorJS: PropTypes.any
    })
  }),
  metaDescription: PropTypes.any,
  onAuthorChange: PropTypes.func,
  onMetaDescriptionChange: PropTypes.func,
  onTitleChange: PropTypes.func,
  onUrlPaste: PropTypes.func,
  setCategory: PropTypes.func,
  setTags: PropTypes.func,
  tags: PropTypes.any,
  title: PropTypes.string
}

export default PostServiceFieldsForm
