import { message } from 'antd'
import { useTranslations } from 'contexts/Translation'
import { useState } from 'react'

const useDeletePost = (id) => {
  // [COMPONENT STATE]
  const [isDeleting, setIsDeleting] = useState(false)

  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  const handleDelete = async () => {
    try {
      setIsDeleting(true)
      const data = await fetch(
        `https://us-central1-menta-blog.cloudfunctions.net/posts`,
        {
          method: 'DELETE',
          headers: { collection_path: 'posts', id: id }
        }
      )
      if (data.statusCode === 202)
        message.success(t('Document successfully deleted'))

      setIsDeleting(false)
    } catch (error) {
      message.error(error.message)
      setIsDeleting(false)
    }
  }

  return [handleDelete, isDeleting]
}

export default useDeletePost
