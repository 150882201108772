import _ from 'lodash'
import { useState, useCallback } from 'react'
import { saveHasManyRelationship, updateDocument } from 'services/firestore'
import { useTranslations } from 'contexts/Translation'

const usePostFormState = (postId) => {
  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  // [COMPONENT STATE]
  const [form, setForm] = useState()
  const [isUpdating, setIsUpdating] = useState(false)

  // [CRUD]
  const updatePost = async (data) => {
    const additionalData = { isDraft: true, path: null, publishingDate: null }
    await updateDocument('posts', postId, { ...additionalData, ...data })
  }

  // [HANDLERS]
  const handleChange = async (stateData = {}, additionalDataForUpdate = {}) => {
    setIsUpdating(true)
    setForm((prev) => ({ ...prev, ...stateData }))
    await updatePost({ ...stateData, ...additionalDataForUpdate })
    setIsUpdating(false)
  }
  const handleUploadImage = (url) => handleChange({ previewImageUrl: url })
  const handleCategoryChange = (categoryId) => handleChange({ categoryId })
  const handleTitleChange = (title) => handleChange({ title })
  const handleAuthorChange = (author) => handleChange({ author })

  const handleMetaDescriptionChange = (value) => {
    handleChange({ metaDescription: value })
  }
  const handleTagsChange = async (_tags) => {
    const newTags = await saveHasManyRelationship('tags', _tags)
    handleChange({ tags: newTags })
  }
  const handleDescriptionChange = (description, descriptionPreview) => {
    description !== form.description &&
      form.description !== undefined &&
      handleChange(
        { description },
        { descriptionPreview: descriptionPreview || '' }
      )
  }
  const handleUrlPaste = async (urlsMeta, editor) => {
    const urlMeta = urlsMeta?.[0]

    const { title = '', metaDescription = '', image } = urlMeta
    const updatedData = { title, metaDescription }
    if (image) updatedData.previewImageUrl = image

    if (editor) {
      const { description } = form

      const title = t('Resources')
      const blocksCount = editor.blocks.getBlocksCount()
      const mappedUrlsMeta = urlsMeta
        .map(({ url }) => `<a href="${url}">${url}</a>`)
        .join('<br>')
      const resText = description.includes(title) ? '' : `<b>${title}</b>:<br>`
      const blocksData = { text: `${resText}${mappedUrlsMeta}` }
      editor.blocks.insert('paragraph', blocksData, {}, blocksCount)
    }

    handleChange(updatedData)
  }

  const handlers = {
    onUploadPreviewImage: handleUploadImage,
    onTagsChange: handleTagsChange,
    onCategoryChange: handleCategoryChange,
    onTitleChange: handleTitleChange,
    onDescriptionChange: handleDescriptionChange,
    onMetaDescriptionChange: handleMetaDescriptionChange,
    onAuthorChange: handleAuthorChange,
    onUrlPaste: handleUrlPaste
  }

  // [INIT]
  const initForm = useCallback(
    (initialData) => {
      if (!isUpdating && !_.isEqual(form, initialData)) setForm(initialData)
    },
    [form, isUpdating]
  )

  return [form, initForm, handlers, form !== undefined]
}

export default usePostFormState
