import { message } from 'antd'
import { useCallback } from 'react'
import { useTranslations } from 'contexts/Translation'

const REACT_APP_LANDING_APPLICATION_URL =
  process.env.REACT_APP_LANDING_APPLICATION_URL
const POST_PAGE_URL = 'innlegg/'

const useSharePost = () => {
  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  const share = useCallback(
    async (id) => {
      try {
        const postUrl = `${REACT_APP_LANDING_APPLICATION_URL}${POST_PAGE_URL}${id}`
        await navigator?.clipboard?.writeText(postUrl)
        message.success(t('Link successfully copied'))
      } catch (error) {
        message.error(error.message)
      }
    },
    [t]
  )

  return share
}

export default useSharePost
