import { useEffect, useState } from 'react'

import { getDocument } from 'services/firestore'

export default function usePostTags(post) {
  const [tags, setTags] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const promises =
        post && post?.tags?.map((tag) => getDocument('tags', tag))
      const data = promises && (await Promise.all(promises))
      setTags(data)
      setLoading(false)
    }

    fetchData()
  }, [post])

  return [tags, loading]
}
