import { PageWrapper, Container } from '@qonsoll/react-design'
import { PostAdvancedForm } from 'domains/Post/components'

export default function PostCreate() {
  return (
    <PageWrapper firstLevelHidden alignMiddle>
      <Container bounded>
        <PostAdvancedForm />
      </Container>
    </PageWrapper>
  )
}
