import { message } from 'antd'
import { updateDocument } from 'services/firestore'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { useCheckPostTitleAvailability } from '../../check'
import { useTranslations } from 'contexts/Translation'
import { transformTextIntoPath } from 'helpers'

const usePublishPost = (id, title) => {
  // [ADDITIONAL HOOKS]
  const history = useHistory()
  const checkTitle = useCheckPostTitleAvailability()
  const { t } = useTranslations()

  const handlePublish = async (_postId, _title) => {
    try {
      const path = transformTextIntoPath(title || _title)
      await checkTitle(title || _title, path)

      const updateData = {
        publishingDate: moment().toISOString(),
        path: path || null,
        isDraft: false
      }

      await updateDocument('posts', id || _postId, updateData)

      message.success(t('Post successfully published'))
      history.push(`/posts/${id || _postId}`)
    } catch (error) {
      message.error(error.message)
    }
  }

  return handlePublish
}

export default usePublishPost
