import { Box, Text } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { useCalculateUpdateDifference } from './hooks'

const UpdateStatus = (props) => {
  const { lastUpdateDate } = props

  const differenceText = useCalculateUpdateDifference(lastUpdateDate?.seconds)

  return (
    <Box display="flex" height="100%">
      <Text fontSize="12px" lineHeight="12px" type="caption2">
        {differenceText}
      </Text>
    </Box>
  )
}

UpdateStatus.propTypes = {
  lastUpdateDate: PropTypes.shape({
    seconds: PropTypes.any
  })
}

export default UpdateStatus
