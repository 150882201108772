import { message } from 'antd'
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore'
import { deleteDocument, updateDocument } from 'services/firestore'
import firebase from 'firebase/compat/app'

const useTagDelete = (tagId) => {
  const [postsWithTag] = useCollectionDataOnce(
    tagId &&
      firebase
        .firestore()
        .collection('posts')
        .where('tags', 'array-contains', tagId)
  )

  const handleDelete = async () => {
    try {
      await deleteDocument('tags', tagId)
      // Updating references
      if (postsWithTag?.length) {
        await Promise.all(
          postsWithTag.map(({ _id, tags }) =>
            updateDocument('posts', _id, {
              tags: tags.filter((id) => id !== tagId)
            })
          )
        )
      }

      message.success('Tag successfully deleted')
    } catch (error) {
      message.error(error.message)
    }
  }

  return handleDelete
}

export default useTagDelete
