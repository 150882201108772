import { Grid } from 'antd'
import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Back, Box, Button, Col, Row, Title } from '@qonsoll/react-design'
import { useTranslations } from 'contexts/Translation'
import PostFormActions from '../PostFormActions'
import UpdateStatus from '../UpdateStatus'
import OnlineUsers from '../OnlineUsers'
import { EyeOutlined } from '@ant-design/icons'

const { useBreakpoint } = Grid

const PostFormHeader = (props) => {
  const {
    onPublish,
    postId,
    lastUpdateDate,
    isDraft,
    author,
    onUploadPreviewImage,
    toggleAdditionalFields,
    additionalFieldsVisible,
    toggleRightAside
  } = props

  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()
  const { xs } = useBreakpoint()
  const history = useHistory()

  // [COMPUTED PROPERTIES]
  const title = useMemo(
    () => (isDraft ? t('Draft post') : t('Published post')),
    [t, isDraft]
  )
  const titleAuthorInfo = useMemo(
    () => (xs ? '' : ` ${t('of')} ${author}`),
    [author, t, xs]
  )
  const titleComputed = useMemo(
    () => title + titleAuthorInfo,
    [title, titleAuthorInfo]
  )

  // [FUNCTIONS]
  const goBack = () => history.goBack()
  const handlePreview = () => history.push(`/posts/${postId}`)

  return (
    <Row h="between" v="center" noGutters mb={2}>
      <Col cw={[12, 12, 12, 'auto']}>
        <Box display="flex" alignItems="center">
          <Back divided onClick={goBack} />
          <Box mb={[2, 2, 2, 0]}>
            <Title level={4}>{titleComputed}</Title>
            <UpdateStatus lastUpdateDate={lastUpdateDate} />
          </Box>
        </Box>
      </Col>
      <Col cw={[12, 12, 12, 'auto']} flexDirection="row">
        <OnlineUsers postId={postId} mr={2} />
        <Button
          width="100%"
          mr={2}
          disabled={!isDraft}
          type="primary"
          onClick={onPublish}
        >
          {t('Publish')}
        </Button>
        <Button mr={2} onClick={handlePreview} width="40px">
          <EyeOutlined width="40px" />
        </Button>
        <PostFormActions
          postId={postId}
          onUploadPreviewImage={onUploadPreviewImage}
          toggleAdditionalFields={toggleAdditionalFields}
          additionalFieldsVisible={additionalFieldsVisible}
          toggleRightAside={toggleRightAside}
        />
      </Col>
    </Row>
  )
}

PostFormHeader.propTypes = {
  additionalFieldsVisible: PropTypes.bool,
  author: PropTypes.string,
  isDraft: PropTypes.bool,
  lastUpdateDate: PropTypes.object,
  onPublish: PropTypes.func,
  onUploadPreviewImage: PropTypes.func,
  postId: PropTypes.string,
  toggleAdditionalFields: PropTypes.func,
  toggleRightAside: PropTypes.func
}

export default PostFormHeader
