import { doc, updateDoc } from 'firebase/firestore'
import { serverTimestamp } from 'firebase/firestore'

import { firestore } from '../firebase'

const updateDocument = async (collectionPath, id, data) => {
  const serviceData = { _updatedAt: serverTimestamp() }
  const ref = doc(firestore, collectionPath, id)
  return await updateDoc(ref, { ...serviceData, ...data })
}

export default updateDocument
