import { Col, Row } from '@qonsoll/react-design'
import { useCallback, useEffect, useState } from 'react'

import LocalizationItem from '../LocalizationItem'
import PropTypes from 'prop-types'
import { message, Typography } from 'antd'
import { useTranslations } from '../../../../contexts/Translation'

import { get, ref, getDatabase } from 'firebase/database'
import { formatTranslations } from '../../helpers'

const { Title } = Typography

const useGetRDBData = (path) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchData = useCallback(async () => {
    try {
      setLoading(true)
      const db = getDatabase()
      const dbRef = ref(db, path)
      const snapshot = await get(dbRef)

      const data = snapshot.val()

      setData(formatTranslations(data))
    } catch (error) {
      message.error(error.message)
    }
    setLoading(false)
  }, [path])

  useEffect(() => {
    path && fetchData()
  }, [path, fetchData])

  return [data, loading, fetchData]
}

const LocalizationForm = (props) => {
  // Props destructuring
  const { appName } = props

  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()
  const [translations = [], loading, fetchTranslations] = useGetRDBData(
    `translations/${appName}`
  )

  return (
    <>
      <Row mb={2} noOuterGutters>
        <Col>
          <Title level={5}>{t('English')}</Title>
        </Col>
        <Col>
          <Title level={5}>{t('Norwegian')}</Title>
        </Col>
      </Row>

      <Row mb={2} noOuterGutters height="100%" v="center">
        <Col>
          {/* <LoadingBox loading={loading} spinnerProps={{ size: 'large' }}> */}
          {/* extra localization item for the addition of new translations */}
          <LocalizationItem
            key={`${appName}-00`}
            appName={appName}
            enPlaceholder={t('Enter text for EN translation')}
            noPlaceholder={t('Enter text for NO translation')}
            withTooltips
            creationEnabled
            triggerTranslationsRefetch={fetchTranslations}
          />

          {!loading &&
            translations.map((translation, index) => (
              <LocalizationItem
                key={`${appName}-${index}`}
                appName={appName}
                data={translation}
              />
            ))}
          {/* </LoadingBox> */}
        </Col>
      </Row>
    </>
  )
}

LocalizationForm.propTypes = {
  appName: PropTypes.string.isRequired,
  isPageLoading: PropTypes.bool,
  handleLoadingChange: PropTypes.func
}

export default LocalizationForm
