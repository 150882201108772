import firebase from 'firebase/compat/app'
import { useCallback } from 'react'
import { useTranslations } from 'contexts/Translation'

const useCheckCategoryNameAvailability = () => {
  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  const checkName = useCallback(
    async (name, path) => {
      if (!name) throw new Error(t('Name is required'))

      const categoriesSnapshot = await firebase
        .firestore()
        .collection('categories')
        .get()

      const categoriesNames = categoriesSnapshot.docs.map(
        (doc) => doc.data().name
      )
      const categoriesPaths = categoriesSnapshot.docs.map(
        (doc) => doc.data().path
      )

      if (categoriesNames.includes(name) || categoriesPaths.includes(path)) {
        throw new Error(`${t('This name is already used!')}`)
      }
    },
    [t]
  )

  return checkName
}

export default useCheckCategoryNameAvailability
