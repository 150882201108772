import { Container, Row, Col, Option } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'
import { useCategories } from 'domains/Category/hooks'
import { StyledSelect } from './CategorySelect.styled'
import { useMemo } from 'react'

const CategorySelect = (props) => {
  const { category, setCategory, placeholder } = props

  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  // [DATA FETCH]
  const [categories] = useCategories({ orderBy: 'name' })

  // [FUNCTIONS]
  const handleChange = (value) => setCategory(value)

  // [COMPUTED PROPERTIES]
  const computedPlaceholder = useMemo(
    () => placeholder || t('Please, select category'),
    [placeholder, t]
  )

  return (
    <Container>
      <Row>
        <Col px="0px">
          <StyledSelect
            placeholder={computedPlaceholder}
            value={category}
            onChange={handleChange}
          >
            {categories?.map((category) => (
              <Option key={category._id} value={category._id}>
                {category.name}
              </Option>
            ))}
          </StyledSelect>
        </Col>
      </Row>
    </Container>
  )
}

CategorySelect.propTypes = {
  category: PropTypes.shape({
    _id: PropTypes.any,
    name: PropTypes.any
  }),
  placeholder: PropTypes.string,
  setCategory: PropTypes.func
}

export default CategorySelect
