import { message } from 'antd'
import { updateDocument } from 'services/firestore'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const useUnpublishPost = (id) => {
  // [ADDITIONAL HOOKS]
  const history = useHistory()
  const { t } = useTranslations()

  const handlePublish = async (_postId) => {
    try {
      const updateData = {
        publishingDate: null,
        path: null,
        isDraft: true
      }

      await updateDocument('posts', id || _postId, updateData)

      message.success(t('Post successfully unpublished'))
      history.push(`/posts/`)
    } catch (error) {
      message.error(error.message)
    }
  }

  return handlePublish
}

export default useUnpublishPost
