import { useCallback, useRef } from 'react'

const useRichTextEditorSubmit = () => {
  /* Creating text editor ref for have access to text editor state */
  const ref = useRef()

  const submit = useCallback(
    async (_ref) => {
      const textEditorData = await (_ref || ref)?.current?.save?.()
      return textEditorData?.blocks?.length ? textEditorData : null
    },
    [ref]
  )

  return [submit, ref]
}

export default useRichTextEditorSubmit
