import PropTypes from 'prop-types'
import { Card, Ribbon } from '@qonsoll/react-design'
import { useMemo } from 'react'
import styled from 'styled-components'

const CardStyled = styled(Card)`
  overflow: hidden;
  border-width: 1px;
  ${({ isSelected }) =>
    isSelected
      ? `background-color: var(--ql-color-accent1-t-lighten6); border-color: var(--ql-color-accent1-t-lighten4)`
      : `background-color: unset; border-color: transparent`}
  ${({ isHorizontalLayout }) =>
    isHorizontalLayout
      ? `border-radius: 0 0 64px 0;`
      : `border-radius: 0 64px 0 0;`}
`
const StyledRibbon = styled(Ribbon)`
  ${({ isDraft }) => (isDraft ? `display: flex;` : `display: none`)}
`

export const SelectableCardStyled = (props) => {
  const {
    _id,
    children,
    selectedItem,
    setSelectedItem,
    isDraft,
    ribbonText,
    ...rest
  } = props

  // [COMPUTED PROPERTIES]
  const bodyStyles = useMemo(() => ({ padding: '0px 0px' }), [])
  const isSelected = useMemo(() => _id === selectedItem, [_id, selectedItem])

  const handleSelect = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setSelectedItem?.(_id)
  }

  return (
    <StyledRibbon isDraft={isDraft} placement="end" text={ribbonText}>
      <CardStyled
        {...rest}
        isSelected={isSelected}
        onClick={handleSelect}
        onContextMenu={(e) => e.preventDefault()}
        bodyStyle={bodyStyles}
      >
        {children}
      </CardStyled>
    </StyledRibbon>
  )
}

SelectableCardStyled.propTypes = {
  _id: PropTypes.string,
  children: PropTypes.any,
  isDraft: PropTypes.bool,
  isHorizontalLayout: PropTypes.bool,
  ribbonText: PropTypes.any,
  selectedItem: PropTypes.string,
  setSelectedItem: PropTypes.func
}
