import { Button, Col, Container, Row, Title } from '@qonsoll/react-design'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Form } from 'antd'
import { CategorySimpleForm } from '../'
import PropTypes from 'prop-types'
import { useSimpleFormActions } from 'hooks'
import { useState } from 'react'
import { CardDropdown } from 'components'
import { useCategoryDelete } from 'domains/Category/hooks'
import { useUpdateCategory } from '../../hooks'

const blockSelectStyles = { userSelect: 'none', cursor: 'pointer' }

const CategorySimpleView = (props) => {
  const { setIsItemEdited, category } = props

  // [COMPONENT STATE]
  const [isEditing, setIsEditing] = useState(false)

  // [ADDITIONAL HOOKS]
  const [form] = Form.useForm()
  const handleDelete = useCategoryDelete(category?._id)
  const update = useUpdateCategory()

  // [FUNCTIONS]
  const { handleEdit, handleCancel } = useSimpleFormActions({
    form,
    changeStateAction: setIsEditing,
    document: category,
    collectionName: 'categories'
  })

  const handleSave = () => {
    const { name } = form.getFieldsValue()

    update(category?._id, { name })
  }

  return (
    <Container style={blockSelectStyles} py="12px">
      {!isEditing ? (
        <CardDropdown
          handleDelete={handleDelete}
          handleEdit={() => {
            setIsItemEdited(true)
            handleEdit()
          }}
        >
          <Row py="8px" cursor="pointer">
            <Col>
              <Row flex="1">
                <Col px="0px" justifyContent="center" cw={9}>
                  <Title
                    overflow="hidden"
                    textOverflow="ellipsis"
                    maxWidth="250px"
                    whiteSpace="nowrap"
                    level={5}
                  >
                    {category?.name}
                  </Title>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardDropdown>
      ) : (
        <Row v="center">
          <Col>
            <CategorySimpleForm
              onSubmit={handleSave}
              form={form}
              initialValues={category}
              showTitle={false}
            />
          </Col>
          <Col cw="auto" display="block">
            <Button
              ghost
              onClick={handleCancel}
              shape="circle"
              type="primary"
              icon={<CloseOutlined />}
            />
            <Button
              ml="8px"
              shape="circle"
              type="primary"
              onClick={handleSave}
              icon={<CheckOutlined />}
            />
          </Col>
        </Row>
      )}
    </Container>
  )
}

CategorySimpleView.propTypes = {
  category: PropTypes.object,
  isItemEdited: PropTypes.bool,
  setIsItemEdited: PropTypes.func
}

export default CategorySimpleView
