import { Box, Col, Container, Row, Text } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { memo, useMemo } from 'react'
import {
  StyledDescription,
  StyledTitle,
  StyledImg
} from './SnapshotSimpleView.styled'
import LazyLoad from 'react-lazy-load'
import { useTranslations } from 'contexts/Translation'
import { useDoubleClick } from 'hooks/events'
import { useApplySnapshot, useDeleteSnapshot } from '../../hooks'
import { SnapshotCardDropdown } from './components'

const blockSelectStyles = { userSelect: 'none', cursor: 'pointer' }

const SnapshotSimpleView = (props) => {
  const { post, postId, snapshotId } = props
  const { previewImageUrl, author, title, descriptionPreview } = post

  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()
  const applySnapshot = useApplySnapshot()
  const deleteSnapshot = useDeleteSnapshot(postId, snapshotId)

  // [COMPUTED PROPERTIES]
  const imageColResponsiveStyles = useMemo(
    () => ({ cw: 12, height: 'unset', order: 1, pr: 0, pl: 0 }),
    []
  )
  const textColResponsiveStyles = useMemo(
    () => ({ cw: 12, order: 2, px: 0 }),
    []
  )

  // [HANDLERS]
  const handleApplySnapshot = () => applySnapshot(postId, post)
  const handleDoubleClick = useDoubleClick(handleApplySnapshot)

  return (
    <Container
      bg="white"
      cursor="pointer"
      style={blockSelectStyles}
      height="inherit"
      onClick={handleDoubleClick}
    >
      <SnapshotCardDropdown
        onApply={handleApplySnapshot}
        onRemove={deleteSnapshot}
      >
        <Row height="inherit">
          <Col {...imageColResponsiveStyles} v="between" h="between">
            <LazyLoad height="200px" once>
              <StyledImg src={previewImageUrl} />
            </LazyLoad>
          </Col>
          <Col {...textColResponsiveStyles}>
            <Box p="32px 16px">
              <Row noGutters h="between">
                <Col>
                  <Text variant="caption1" fontSize="14px">
                    {author}
                  </Text>
                </Col>
              </Row>
              <StyledTitle my="16px" level={3}>
                {title || t('Untitled')}
              </StyledTitle>
              <StyledDescription>{descriptionPreview}</StyledDescription>
            </Box>
          </Col>
        </Row>
      </SnapshotCardDropdown>
    </Container>
  )
}

SnapshotSimpleView.propTypes = {
  isHorizontalLayout: PropTypes.bool,
  post: PropTypes.object,
  postId: PropTypes.string,
  snapshotId: PropTypes.string
}

export default memo(SnapshotSimpleView)
