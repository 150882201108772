import { PageWrapper, Container, Row, Col, Button } from '@qonsoll/react-design'
import { TagAdvancedView } from 'domains/Tag/components'
import { useTranslations } from 'contexts/Translation'
import { HeaderBreadcrumbs } from 'components'
import { useParams, useHistory } from 'react-router-dom'
import { EditOutlined } from '@ant-design/icons'

export default function TagShow() {
  const { t } = useTranslations()
  const history = useHistory()
  const params = useParams()
  const { tagId } = params
  const headingProps = {
    title: t('Tag'),
    titleSize: 2
  }

  return (
    <PageWrapper
      action={
        <Button
          icon={<EditOutlined />}
          onClick={() => history.push(`${tagId}/edit`)}
        >
          {t('Edit')}
        </Button>
      }
      onBack={() => history?.goBack()}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}
    >
      <Container bounded xl>
        <Row marginTop="8px">
          <Col>
            <TagAdvancedView />
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  )
}
