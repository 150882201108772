import { Dropdown, Menu } from 'antd'
import PropTypes from 'prop-types'
import { Button } from '@qonsoll/react-design'
import { useTranslations } from 'contexts/Translation'
import { Icon } from '@qonsoll/icons'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { RemoveMenuItem } from 'components'
// import { useUploadPreviewImage } from '../../hooks'
import { useDeletePost } from 'domains/Post/hooks'

const PostFormActions = (props) => {
  const {
    postId,
    // onUploadPreviewImage,
    toggleAdditionalFields,
    additionalFieldsVisible,
    toggleRightAside
  } = props

  // [COMPONENT STATE]
  const [isDropDownOpened, setOpen] = useState(false)

  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  // const upload = useUploadPreviewImage()
  const [deletePost] = useDeletePost(postId)

  // [FUNCTIONS]
  const toggleOpen = () => setOpen((prev) => !prev)
  const handleRemove = async () => {
    await deletePost()
    history.goBack()
  }
  // const handleUpload = async (data) => {
  //   const { file } = data
  //   const url = await upload(file, postId)
  //   if (url) {
  //     onUploadPreviewImage(url)
  //     toggleOpen()
  //   }
  // }
  const handleAdditionalFieldsToggle = () => {
    toggleAdditionalFields()
    toggleOpen()
  }
  const handleRightAsideToggle = () => {
    toggleRightAside()
    toggleOpen()
  }

  // [COMPUTED PROPERTIES]
  const menu = (
    <Menu>
      <Menu.Item onClick={handleAdditionalFieldsToggle} key="additionalFields">
        {additionalFieldsVisible
          ? t('Hide additional fields')
          : t('Show additional fields')}
      </Menu.Item>
      {toggleRightAside && (
        <Menu.Item onClick={handleRightAsideToggle} key="snapshots">
          {t('Snapshots')}
        </Menu.Item>
      )}
      <RemoveMenuItem key="remove" icon={null} onClick={handleRemove} />
    </Menu>
  )

  return (
    <Dropdown overlay={menu} visible={isDropDownOpened} placement="bottomRight">
      <Button
        onClick={toggleOpen}
        icon={
          <Icon
            width="40px"
            justifyContent="center"
            name="MenuVerticalFilled"
          />
        }
      />
    </Dropdown>
  )
}

PostFormActions.propTypes = {
  additionalFieldsVisible: PropTypes.bool,
  onUploadPreviewImage: PropTypes.func,
  postId: PropTypes.string,
  toggleAdditionalFields: PropTypes.func,
  toggleRightAside: PropTypes.func
}

export default PostFormActions
