import { doc, serverTimestamp, setDoc } from 'firebase/firestore'

import { firestore } from '../firebase'
import { getId } from 'services/firestore'

const createDocument = async (collectionPath, documentData) => {
  const id = getId(collectionPath)
  const ref = doc(firestore, collectionPath, id)

  const serviceData = {
    _id: id,
    _updatedAt: serverTimestamp(),
    _createdAt: serverTimestamp()
  }

  await setDoc(ref, { ...serviceData, ...documentData })

  return { id }
}

export default createDocument
