import './styles.css'

const Delimiter = () => {
  return (
    <div className="delimiter-wrapper">
      <div className="delimiter" />
    </div>
  )
}

export default Delimiter
