import stripHtml from './stripHtml'

export const transformContentIntoText = (blocks) => {
  return blocks
    ? blocks
        .slice(0, 3)
        .filter(({ type }) => !!['header', 'paragraph'].includes(type))
        .map(({ data }) => (data?.text ? stripHtml(data?.text) : ''))
        .join('. ')
    : ''
}

export default transformContentIntoText
