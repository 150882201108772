import { uploadBytes, getDownloadURL } from 'firebase/storage'
import storageRef from './storageRef'

const uploadFile = (file, path) => {
  const ref = storageRef(path)
  const uploadPromise = uploadBytes(ref, file)

  return uploadPromise.then(async (snapshot) => {
    const downloadUrl = await getDownloadURL(snapshot.ref)

    return [downloadUrl, snapshot]
  })
}

export default uploadFile
