import { AccountMenu, LayoutHeader, Logo, MainMenu } from './components'
import {
  Aside,
  Box,
  Button,
  Layout,
  LayoutSystemProvider
} from '@qonsoll/react-design'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { useMemo } from 'react'

import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import breakpoints from '../../styles/breakpoints'
import firebase from 'firebase/compat/app'
import { useAuthState } from 'react-firebase-hooks/auth'
import CollapseButtonWrapper from './CollapseButtonWrapper.styled'
import { useStateWithStorage } from 'hooks'

const BoilerplateLayout = ({ children }) => {
  const [user, loading] = useAuthState(firebase.auth())

  /* If the user is authenticated, the component will render the children. Otherwise, it will render
  the fallback component. */
  const isAuthenticated = useMemo(
    () => user?.email && user?.emailVerified && !loading,
    [user?.email, user?.emailVerified, loading]
  )

  const [asideLeftCollapsed, setAsideLeftCollapsed] = useStateWithStorage(
    true,
    'mainMenuCollapsed'
  )
  const onAsideToggle = () => setAsideLeftCollapsed(!asideLeftCollapsed)

  /* The layoutConfig is memoized to only re-render when isAuthenticated changes. */
  const layoutConfig = {
    isAsideLeft: isAuthenticated,
    asideLeftCollapseVariant: 'short',
    asideLeftCollapsed,
    asideLeftOuter: true
  }

  return (
    <ThemeProvider theme={breakpoints}>
      {isAuthenticated ? (
        <LayoutSystemProvider {...layoutConfig}>
          <Layout
            asideLeft={
              <Aside collapse={asideLeftCollapsed} bg="var(--menta-accent)">
                <CollapseButtonWrapper collapse={asideLeftCollapsed}>
                  <Button
                    color="white"
                    alignSelf="flex-end"
                    ghost
                    size="small"
                    type="text"
                    icon={
                      !asideLeftCollapsed ? <LeftOutlined /> : <RightOutlined />
                    }
                    onClick={onAsideToggle}
                  />
                </CollapseButtonWrapper>
                <Box mt={asideLeftCollapsed ? 24 : 0}>
                  <Logo
                    isAuthenticated={isAuthenticated}
                    isCollapsed={asideLeftCollapsed}
                  />
                </Box>
                <Box mb="auto" mx={!asideLeftCollapsed ? -24 : 0}>
                  <MainMenu collapse={asideLeftCollapsed} />
                </Box>
                <Box display="flex" justifyContent="center">
                  <AccountMenu
                    asideLeftCollapsed={asideLeftCollapsed}
                    id={user?.uid}
                    avatar={user?.photoURL}
                    displayName={user?.displayName}
                    email={user?.email}
                  />
                </Box>
              </Aside>
            }
          >
            {children}
          </Layout>
        </LayoutSystemProvider>
      ) : (
        <LayoutSystemProvider {...layoutConfig}>
          <Layout
            header={<LayoutHeader center={<Logo isAuthenticated={false} />} />}
          >
            {children}
          </Layout>
        </LayoutSystemProvider>
      )}
    </ThemeProvider>
  )
}

BoilerplateLayout.propTypes = {
  children: PropTypes.element
}

export default BoilerplateLayout
