import { PageWrapper, Container, Row, Col, Spin } from '@qonsoll/react-design'
import { CategoryAdvancedForm } from 'domains/Category/components'
import { useTranslations } from 'contexts/Translation'
import { HeaderBreadcrumbs } from 'components'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useDocument } from 'hooks'

export default function CategoryEdit() {
  const { t } = useTranslations()
  const history = useHistory()
  const headingProps = {
    title: t('Edit category'),
    titleSize: 2
  }
  const params = useParams()
  const [loading, setLoading] = useState(true)
  const { categoryId } = params

  const [category] = useDocument({ ref: `categories/${categoryId}` })

  useEffect(() => {
    category && setLoading(false)
  }, [category])

  return (
    <PageWrapper
      onBack={() => history?.goBack()}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}
    >
      <Container bounded xl>
        <Row marginTop="8px">
          {loading ? (
            <Col>
              <Spin />
            </Col>
          ) : (
            <Col>
              <CategoryAdvancedForm initialData={{ category }} />
            </Col>
          )}
        </Row>
      </Container>
    </PageWrapper>
  )
}
