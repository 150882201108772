import { Checkbox } from 'antd'
import PropTypes from 'prop-types'
import { useCategories } from 'domains/Category/hooks'
import { useSubfilterActions } from 'hooks'
import { StyledDetails } from '../PostFilter.styles'
import { useTranslations } from 'contexts/Translation'

const CategoryFilter = ({ filterData, setFilterData }) => {
  const [categories] = useCategories()
  const { t } = useTranslations()

  const { onToggleDetails, onChange, checkIsEnabled } = useSubfilterActions({
    filterData,
    setFilterData,
    fieldName: 'categoryId',
    operand: '=='
  })

  return (
    <StyledDetails
      open={localStorage.getItem(`category-details-visibility`) === 'opened'}
    >
      <summary onClick={() => onToggleDetails('category')}>
        {t('Category')}
      </summary>
      {categories ? (
        categories?.map((category, index) => (
          <div key={`status-${index}`}>
            <Checkbox
              key={`status-${index}`}
              checked={checkIsEnabled(category)}
              onChange={() => onChange(category)}
            >
              {category?.name}
            </Checkbox>
          </div>
        ))
      ) : (
        <></>
      )}
    </StyledDetails>
  )
}

CategoryFilter.propTypes = {
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default CategoryFilter
