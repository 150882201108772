import { PageWrapper, Container, Spin } from '@qonsoll/react-design'
import { PostAdvancedForm } from 'domains/Post/components'
import { useParams } from 'react-router-dom'
import { useMemo, useState } from 'react'
import { useListenDocument } from 'hooks'
import { usePostTags } from 'domains/Post/hooks'
import { SnapshotsAside } from 'domains/Post/domains/Snapshot/components'
import { LoadingBox } from 'components'

export default function PostEdit() {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)

  // [ADDITIONAL HOOKS]
  const params = useParams()
  const { postId } = params
  const [post, postLoading] = useListenDocument(`posts/${postId}`)
  const [tags, tagsLoading] = usePostTags(post)

  // [COMPUTED PROPERTIES]
  const loading = useMemo(
    () => postLoading || tagsLoading || !post,
    [postLoading, tagsLoading, post]
  )
  const toggleRightAside = () => setIsDrawerVisible((prev) => !prev)

  return (
    <PageWrapper firstLevelHidden>
      <Container bounded height="inherit">
        {!post && loading ? (
          <LoadingBox loading={loading} alignItems="center" height="90vh">
            <Spin />
          </LoadingBox>
        ) : (
          <PostAdvancedForm
            toggleRightAside={toggleRightAside}
            initialData={{ post, tags }}
          />
        )}
        <SnapshotsAside
          toggle={toggleRightAside}
          visible={isDrawerVisible}
          postId={postId}
        />
      </Container>
    </PageWrapper>
  )
}
