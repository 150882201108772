import PropTypes from 'prop-types'
import { Box, Dropdown, MenuItem } from '@qonsoll/react-design'
import {
  LanguageSelectWrapper,
  Menu,
  dropDownStyles,
  menuItemStyles
} from './LanguageSelect.styled'
import { useEffect, useMemo, useState } from 'react'

import { Icon } from '@qonsoll/icons'
import moment from 'moment'
import { useTranslations } from 'contexts/Translation'

const LANGUAGE_MAP = {
  en: {
    title: 'English',
    shortCode: 'en'
  },
  no: {
    title: 'Norsk',
    shortCode: 'no'
  }
}

function LanguageSelect(props) {
  const { isMenuCollapsed } = props

  // [ADDITIONAL HOOKS]
  const { setCurrentLanguage, language, languages } = useTranslations()

  // [COMPONENT STATE HOOKS]
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false)

  // [HELPER FUNCTIONS]
  const handleChangeLanguage = (shortCodeLanguage) => {
    setIsLanguageMenuOpen(false)
    setCurrentLanguage(shortCodeLanguage)
    moment.locale(shortCodeLanguage === 'no' ? 'nb' : shortCodeLanguage)
  }
  const handleClickSelect = () => setIsLanguageMenuOpen(!isLanguageMenuOpen)

  // [COMPUTED PROPERTIES]
  const menu = (
    <Menu selectedKeys={language} collapse={isMenuCollapsed}>
      {languages?.map(({ shortCode, name }, index) => (
        <MenuItem
          style={menuItemStyles}
          key={shortCode || index}
          value={shortCode}
          onClick={() => handleChangeLanguage(shortCode)}
        >
          {isMenuCollapsed ? shortCode : name}
        </MenuItem>
      ))}
    </Menu>
  )
  const title = useMemo(
    () => (isMenuCollapsed ? language : LANGUAGE_MAP?.[language].title),
    [isMenuCollapsed, language]
  )

  // [USE EFFECTS]
  useEffect(() => {
    if (!isMenuCollapsed)
      document.getElementById('selectedLanguageNameId').className =
        'animate__animated animate__fadeIn'
  }, [isMenuCollapsed])

  return (
    <Dropdown
      overlay={menu}
      visible={isLanguageMenuOpen}
      style={dropDownStyles}
    >
      <LanguageSelectWrapper
        collapse={isMenuCollapsed}
        onClick={handleClickSelect}
      >
        <Box id="selectedLanguageNameId">{title}</Box>
        {!isMenuCollapsed && (
          <Icon
            name="ArrowShortDownFilled"
            size={24}
            fill="var(--ql-color-white-t-lighten2)"
          />
        )}
      </LanguageSelectWrapper>
    </Dropdown>
  )
}

LanguageSelect.propTypes = {
  isMenuCollapsed: PropTypes.bool
}

export default LanguageSelect
