import { Grid } from 'antd'
import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'
import { DEFAULT_GRADIENT_IMAGE_URL } from '../../../../../../services/storage/defaultFiles'
import { Img, Row } from '@qonsoll/react-design'
import { StyledDragger } from './PostFormImage.styled'
import { useUploadPreviewImage } from '../../hooks'
import { AddItemCard } from 'components'

const { useBreakpoint } = Grid

const PostFormImage = (props) => {
  const { url, onUploadPreviewImage, postId } = props

  // [ADDITIONAL HOOKS]
  const { xs } = useBreakpoint()
  const { t } = useTranslations()
  const upload = useUploadPreviewImage()

  // [COMPUTED PROPERTIES]
  const isVisible = useMemo(() => DEFAULT_GRADIENT_IMAGE_URL !== url, [url])
  const computedHeight = useMemo(
    () => (isVisible ? (xs ? '200px' : '300px') : '80px'),
    [xs, isVisible]
  )
  const computedAlt = useMemo(() => t('Artiqle'), [t])

  // [FUNCTIONS]
  const handleUpload = async (data) => {
    const { file } = data

    const url = await upload(file, postId)
    url && onUploadPreviewImage(url)
  }

  return (
    <StyledDragger
      customRequest={handleUpload}
      accept="image/*"
      name="file"
      multiple={false}
      height={computedHeight}
      fileList={null}
    >
      {isVisible ? (
        <Img
          src={url}
          alt={computedAlt}
          height={computedHeight}
          width="100%"
          style={{ objectFit: 'cover', objectPosition: 'center' }}
        />
      ) : (
        <Row noGutters>
          <AddItemCard
            message={t('Add main photo')}
            border="unset"
            cardWidth={12}
          />
        </Row>
      )}
    </StyledDragger>
  )
}

PostFormImage.propTypes = {
  url: PropTypes.string,
  onUploadPreviewImage: PropTypes.func,
  postId: PropTypes.string
}

export default PostFormImage
