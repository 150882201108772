const PATHS = {
  CONFIG: {
    DEFAULT: '/auth',
    AFTER_LOGIN: '/posts',
    AFTER_LOGOUT: '/auth',
    AFTER_SIGNUP: '/posts'
  },
  UNAUTHENTICATED: {
    LOGIN: '/auth',
    LOGIN_WITH_EMAIL: '/auth/login-with-email',
    SIGNUP: '/auth/signup',
    SIGNUP_WITH_EMAIL: '/auth/sign-up-with-email',
    FORGOT_PASSWORD: '/auth/forgot-password',
    CONFIRM_EMAIL: '/auth/confirm-email'
  },
  SERVICE: {
    ACCESS_DENIED: '/service/access-denied',
    GDPR: '/service/gdpr',
    NOT_FOUND: '/service/404',
    TERMS_AND_CONDITIONS: '/service/terms-and-conditions'
  },
  AUTHENTICATED: {
    DASHBOARD: '/dashboard',
    USER_SHOW: '/users/:userId',
    POSTS_ALL: '/posts',
    POST_SHOW: '/posts/:postId',
    POST_CREATE: '/post/create',
    POST_EDIT: '/posts/:postId/edit',
    TAGS_ALL: '/tags',
    TAG_SHOW: '/tags/:tagId',
    TAG_CREATE: '/tag/create',
    TAG_EDIT: '/tags/:tagId/edit',
    CATEGORIES_ALL: '/categories',
    CATEGORY_SHOW: '/categories/:categoryId',
    CATEGORY_CREATE: '/category/create',
    CATEGORY_EDIT: '/categories/:categoryId/edit',
    TRANSLATIONS: '/translations'
  }
}

export default PATHS
