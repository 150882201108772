import { message } from 'antd'
import { useTranslations } from 'contexts/Translation'
import { useCallback } from 'react'
import { updateDocument } from 'services/firestore'

const useApplySnapshot = () => {
  const { t } = useTranslations()

  const applySnapshot = useCallback(
    async (postId, snapshotData) => {
      try {
        const additionalData = {
          isDraft: true,
          path: null,
          publishingDate: null
        }
        await updateDocument('posts', postId, {
          ...additionalData,
          ...(snapshotData || {})
        })
        message.success(t('Snapshot applied'))
      } catch (error) {
        message.error(t('Error applying snapshot'))
      }
    },
    [t]
  )

  return applySnapshot
}

export default useApplySnapshot
