import { Box, Menu as QMenu } from '@qonsoll/react-design'

import styled from 'styled-components'

const LanguageSelectWrapper = styled(Box)`
  word-break: keep-all;
  width: 100%;
  padding: 12px;
  background-color: var(--ql-color-white-t-lighten5);
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: ${({ collapse }) => (collapse ? 'center' : 'space-between')};
  align-items: center;
  position: relative;
  color: var(--ql-color-white-t-lighten2);
  transition: var(--ql-transition-default);

  &:hover {
    color: var(--ql-color-white-t-lighten1);
    background-color: var(--ql-color-white-t-lighten4);
  }
`

const Menu = styled(QMenu)`
  width: ${({ collapse }) => (collapse ? '48px' : '256px')};
  transition: var(--ql-transition-default);
`
const menuItemStyles = { paddingLeft: '6px', paddingRight: '6px' }
const dropDownStyles = { display: 'flex', justifyContent: 'center' }

export { LanguageSelectWrapper, Menu, dropDownStyles, menuItemStyles }
