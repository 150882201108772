const useGetUrlsMetaData = () => {
  const getMeta = async (urls = []) => {
    const request = await fetch(
      `https://us-central1-menta-blog.cloudfunctions.net/urls/fetchUrlsMetadata`,
      {
        method: 'POST',
        body: JSON.stringify({ urls })
      }
    )
    const data = (await request.json())?.data
    const urlsDataMapped =
      data?.map((urlData) => ({
        title:
          urlData.title ||
          urlData['og:title'] ||
          urlData['twitter:title'] ||
          null,
        image:
          urlData.image ||
          urlData['og:image'] ||
          urlData['twitter:image'] ||
          null,
        metaDescription:
          urlData.description ||
          urlData['og:description'] ||
          urlData['twitter:description'] ||
          null,
        url: urlData.url || null
      })) || []

    return urlsDataMapped
  }

  return getMeta
}

export default useGetUrlsMetaData
