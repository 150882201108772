import { deleteDocument, updateDocument } from 'services/firestore'
import { message } from 'antd'
import { useTranslations } from 'contexts/Translation'

const useSimpleFormActions = (props) => {
  const { form, changeStateAction, document, collectionName } = props

  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  // [HANDLERS]
  const handleCancel = () => {
    changeStateAction(false)
    form.resetFields()
  }
  const handleEdit = () => changeStateAction(true)
  const handleSave = () => {
    updateDocument(collectionName, document._id, form.getFieldsValue())
      .then(() => message.success(t('Document successfully updated')))
      .catch((error) => message.error(error.message))
    changeStateAction(false)
    form.resetFields()
  }
  const handleDelete = () =>
    deleteDocument(collectionName, document._id)
      .then(() => message.success(t('Document successfully deleted')))
      .catch((error) => message.error(error.message))

  return { handleCancel, handleEdit, handleSave, handleDelete }
}

export default useSimpleFormActions
