import PropTypes from 'prop-types'
import { DeleteOutlined } from '@ant-design/icons'
import { useTranslations } from 'contexts/Translation'
import { MenuItemStyled } from './RemoveMenuItem.styled'

const RemoveMenuItem = (props) => {
  const { icon = true, ...rest } = props

  const { t } = useTranslations()

  return (
    <MenuItemStyled
      {...rest}
      key="remove"
      icon={icon ? <DeleteOutlined /> : null}
    >
      {t('Delete')}
    </MenuItemStyled>
  )
}

RemoveMenuItem.propTypes = {
  icon: PropTypes.any
}

export default RemoveMenuItem
