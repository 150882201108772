import PropTypes from 'prop-types'
import { Box, Img } from '@qonsoll/react-design'
import logoArticle from 'assets/logo-artiqle-full.png'
import logoArticleCollapsed from 'assets/logo-artiqle-symbol.png'
import logoArticleBlack from 'assets/logo-artiqle-full-black.png'
import { useMemo } from 'react'

const Logo = (props) => {
  const { isCollapsed, isAuthenticated } = props

  const logoComputed = useMemo(
    () =>
      isCollapsed
        ? logoArticleCollapsed
        : isAuthenticated
        ? logoArticle
        : logoArticleBlack,
    [isAuthenticated, isCollapsed]
  )

  return (
    <Box
      display="flex"
      justifyContent={isCollapsed ? 'center' : 'start'}
      height="40"
    >
      <Img src={logoComputed} alt="Qonsoll" height="40" />
    </Box>
  )
}

Logo.propTypes = {
  isCollapsed: PropTypes.bool,
  isAuthenticated: PropTypes.bool
}

export default Logo
