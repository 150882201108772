import PropTypes from 'prop-types'
import { Box, Col, Row, Text } from '@qonsoll/react-design'
import { cloneElement, useMemo } from 'react'
import { Icon } from '@qonsoll/icons'

const PostFormItem = (props) => {
  const { tipText, icon, children, ...rest } = props

  // [COMPUTED PROPERTIES]
  const child = useMemo(
    () => (children ? cloneElement(children, { ...(rest || {}) }) : null),
    [children, rest]
  )

  return (
    <Row noOuterGutters v="center">
      <Col cw={[4, 4, 3, 2]} height="inherit">
        {tipText && (
          <Box display="flex" alignItems="center" pr={2}>
            <Icon size="20px" mr={2} name={icon} />
            <Text variant="body1" isEllipsis>
              {tipText}
            </Text>
          </Box>
        )}
      </Col>
      <Col cw={[8, 8, 9, 10]}>{child}</Col>
    </Row>
  )
}

PostFormItem.propTypes = {
  children: PropTypes.any,
  icon: PropTypes.any,
  tipText: PropTypes.any,
  value: PropTypes.any
}

export default PostFormItem
