import Output from 'editorjs-react-renderer'

import { Delimiter } from './components'

const renderers = {
  delimiter: Delimiter
}

const RichTextContent = (props) => {
  return <Output renderers={renderers} {...props} />
}

export default RichTextContent
