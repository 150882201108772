import PropTypes from 'prop-types'
import { Col, Row } from '@qonsoll/react-design'
import SnapshotSimpleView from '../SnapshotSimpleView'
import { useListenSnapshots, useCreateSnapshot } from '../../hooks'
import { SelectableCardStyled } from './SnapshotsList.styled'
import { AddItemCard } from 'components'
import { useTranslations } from 'contexts/Translation'

const SnapshotsList = (props) => {
  const { postId } = props

  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()
  const [snapshots] = useListenSnapshots(postId)
  const handleCreate = useCreateSnapshot(postId)

  return (
    <Row noGutters>
      <AddItemCard
        cardWidth={12}
        mb={3}
        message={t('Make snapshot')}
        onClickAction={handleCreate}
      />
      {snapshots?.map((snapshot) => (
        <Col cw={12} key={snapshot._id} mb={3}>
          <SelectableCardStyled ribbonText={snapshot.name} isDraft>
            <SnapshotSimpleView
              snapshotId={snapshot._id}
              post={snapshot.post}
              postId={postId}
            />
          </SelectableCardStyled>
        </Col>
      ))}
    </Row>
  )
}

SnapshotsList.propTypes = {
  postId: PropTypes.string
}

export default SnapshotsList
