import { Container, PageWrapper } from '@qonsoll/react-design'
// manually_edited
import { useState } from 'react'

import { CustomTabs } from './Translations.styles'
import { LocalizationForm } from '../../../domains/Translation/components'
import { Tabs } from 'antd'
import { useTranslations } from '../../../contexts/Translation'
import { useHistory } from 'react-router-dom'

const { TabPane } = Tabs

const TranslationEdit = () => {
  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()
  const history = useHistory()

  // [COMPONENT STATE HOOKS]
  const [isPageLoading, setIsPageLoading] = useState(true)

  // [COMPUTED PROPERTIES]

  // [HELPER FUNCTIONS]
  const handleLoadingChange = (newValue = false) => {
    if (isPageLoading !== newValue) setIsPageLoading(newValue)
  }

  // [USE EFFECTS]

  return (
    <Container bounded>
      <PageWrapper
        height="100%"
        headingProps={{
          title: t('Translations'),
          titleSize: 2,
          textAlign: 'left'
        }}
        onBack={() => history?.goBack()}
      >
        <CustomTabs
          defaultActiveKey="menta-blog"
          size="large"
          height={isPageLoading ? '100%' : 'initial'} // this is prop for the styled component (affects 2 tab-containers)
        >
          <TabPane tab={'Artiqle'} key="menta-blog">
            <LocalizationForm
              appName="menta-blog"
              isPageLoading={isPageLoading}
              handleLoadingChange={handleLoadingChange}
            />
          </TabPane>
        </CustomTabs>
      </PageWrapper>
    </Container>
  )
}

export default TranslationEdit
