// OTHER possible extensions
// import CheckList from '@editorjs/checklist'
// import Warning from "@editorjs/warning";
// import Raw from "@editorjs/raw";
// import InlineCode from "@editorjs/inline-code";
// import SimpleImage from "@editorjs/simple-image";
// import Code from '@editorjs/code'
// ================================
import Embed from '@editorjs/embed'
import Header from '@editorjs/header'
import InlineCode from '@editorjs/inline-code'
import Marker from '@editorjs/marker'
import Quote from '@editorjs/quote'
// import Table from '@editorjs/table'
// import Delimiter from '@editorjs/delimiter'
import Delimiter from './Delimiter/Delimiter'

export const EDITOR_JS_TOOLS = {
  header: Header,
  inlineCode: InlineCode,
  // table: Table,
  quote: Quote,
  marker: Marker,
  delimiter: Delimiter,
  embed: Embed
}

export default EDITOR_JS_TOOLS
