import { Checkbox } from 'antd'
import PropTypes from 'prop-types'
import { useSubfilterActions } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const DraftFilter = ({ filterData, setFilterData }) => {
  const { onChange, checkIsEnabled } = useSubfilterActions({
    filterData,
    setFilterData,
    fieldName: 'isDraft',
    operand: '=='
  })
  const { t } = useTranslations()

  return (
    <>
      <Checkbox
        checked={checkIsEnabled({ _id: 'isDraft' })}
        onChange={() => onChange({ _id: true })}
      >
        {t('Draft')}
      </Checkbox>
    </>
  )
}

DraftFilter.propTypes = {
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default DraftFilter
