import styledComponents from 'styled-components'
import { Select } from '@qonsoll/react-design'

export const StyledSelect = styledComponents(Select)`
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent;
    background-color: unset;
    border: unset;
  }

  &.ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    box-shadow: none !important;
    background: transparent;
    border: unset;
  }
`
