import './styles.css'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useRichTextEditorSubmit } from './hooks'
import { EDITOR_JS_TOOLS } from './__constants__'
import { getAdditionalTools, transformContentIntoText } from './helpers'
import PropTypes from 'prop-types'
import { createReactEditorJS } from 'react-editor-js'
import { Text } from '@qonsoll/react-design'
import { RichTextContent } from './components'

const RichTextEditor = (props) => {
  const {
    textEditorRef,
    defaultValue,
    readOnly = false,
    placeholder,
    onChange,
    postId
  } = props

  // [ADDITIONAL HOOKS]
  // eslint-disable-next-line no-unused-vars
  const [submitEditor] = useRichTextEditorSubmit()
  const localRef = useRef(null)

  // [COMPUTED PROPERTIES]
  const initialValue = useMemo(
    () => (defaultValue ? JSON.parse(defaultValue) : null),
    [defaultValue]
  )
  const minHeight = useMemo(() => (readOnly ? 0 : 100), [readOnly])

  // [FUNCTIONS]
  const handleChange = async () => {
    try {
      const raw = await submitEditor(textEditorRef)
      if (localRef.current === JSON.stringify(raw?.blocks || null)) return
      const descriptionPreview = transformContentIntoText(raw?.blocks || '')
      const description = raw ? JSON.stringify(raw) : ''

      await onChange?.(description, descriptionPreview)
      localRef.current = JSON.stringify(raw?.blocks || [])
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  const handleInitialize = useCallback(
    async (instance) => {
      if (textEditorRef) textEditorRef.current = instance
    },
    [textEditorRef]
  )

  const additionalTools = useMemo(() => getAdditionalTools(postId), [postId])
  const tools = useMemo(
    () => ({ ...EDITOR_JS_TOOLS, ...additionalTools }),
    [additionalTools]
  )

  const updateEditor = useCallback(async () => {
    const editor = textEditorRef?.current?._editorJS
    if (editor) {
      await editor.isReady
      initialValue ? editor.render(initialValue) : editor.clear()
    }
  }, [initialValue, textEditorRef])

  useEffect(() => {
    if (JSON.stringify(initialValue?.blocks) !== localRef.current)
      updateEditor()
  }, [initialValue, updateEditor])

  return (
    <Text fontSize="20px">
      {readOnly ? (
        <RichTextContent data={initialValue} />
      ) : (
        <EditorWithDelayedSubmit
          onChange={handleChange}
          minHeight={minHeight}
          readOnly={readOnly}
          placeholder={placeholder}
          onInitialize={handleInitialize}
          tools={tools}
        />
      )}
    </Text>
  )
}

RichTextEditor.propTypes = {
  defaultValue: PropTypes.string,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  textEditorRef: PropTypes.shape({
    current: PropTypes.any
  }),
  postId: PropTypes.string
}

export default RichTextEditor

const EditorWithDelayedSubmit = (props) => {
  const { onChange, ...rest } = props

  // [ADDITIONAL HOOKS]
  const timeoutRef = useRef(null)
  const ReactEditorJS = useMemo(() => createReactEditorJS(), [])

  const handleChange = () => {
    if (timeoutRef?.current) clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => onChange?.(), [1000])
  }

  return <ReactEditorJS onChange={handleChange} {...rest} />
}

EditorWithDelayedSubmit.propTypes = {
  onChange: PropTypes.func
}
