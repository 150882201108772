import useCollection from 'hooks/useCollection'

export default function usePosts(props) {
  const ref = 'posts'

  const [value, loading, error, next, loadingMore, loadMoreAvailable] =
    useCollection({ ref, ...props })

  return [value, loading, error, next, loadingMore, loadMoreAvailable]
}
