import { transformTextIntoPath, capitalizeText, normalizeText } from 'helpers'
import { useTranslations } from 'contexts/Translation'
import { message } from 'antd'
import { createDocument } from 'services/firestore'
import { useCheckCategoryNameAvailability } from '../../check'

const useCreateCategory = () => {
  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()
  const checkName = useCheckCategoryNameAvailability()

  const createCategory = async (name, callback) => {
    try {
      const nameComputed = normalizeText(capitalizeText(name))
      const path = transformTextIntoPath(nameComputed)

      await checkName(nameComputed, path)

      const data = { name: nameComputed, path }

      await createDocument('categories', data)
      message.success(t('Category created successfully'))
      callback()
    } catch (error) {
      message.error(error.message)
    }
  }

  return createCategory
}

export default useCreateCategory
