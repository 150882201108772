const transformTextIntoPath = (text) =>
  text
    ?.normalize('NFD') // Normalize diacritics
    ?.replace(/[\u0300-\u036f]/g, '') // Remove diacritics
    ?.replace(/[^\w\s]/gi, '') // Remove special characters
    ?.replace(/\s+/g, ' ') // Replace all white-spaces with a single space
    ?.trim() // Remove leading and trailing spaces
    ?.replace(/\s/g, '-') // Replace all spaces with a hyphen
    ?.toLowerCase() // Convert to lowercase

export default transformTextIntoPath
