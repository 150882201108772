import firebase from 'firebase/compat/app'
import { useCallback } from 'react'
import { useTranslations } from 'contexts/Translation'

const useCheckTagNameAvailability = () => {
  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  const checkName = useCallback(
    async (name, path) => {
      if (!name) throw new Error(t('Name is required'))

      const tagsSnapshot = await firebase.firestore().collection('tags').get()

      const tagsNames = tagsSnapshot.docs.map((doc) => doc.data().name)
      const tagsPaths = tagsSnapshot.docs.map((doc) => doc.data().path)

      if (tagsNames.includes(name) || tagsPaths.includes(path)) {
        throw new Error(`${t('This name is already used!')}`)
      }
    },
    [t]
  )

  return checkName
}

export default useCheckTagNameAvailability
