import {
  CustomInputWithDelayedSubmission,
  CustomTextAreaWithDelayedSubmission
} from './components'
import styledComponents from 'styled-components'

export const StyledInput = styledComponents(CustomInputWithDelayedSubmission)`
  &.ant-input, .ant-input:placeholder-shown, .ant-input:hover {
    background: transparent;
    background-color: unset;
    border: unset;
    outline: 0;
  }
  &.ant-input:focus {
    border-color: transparent;
    outline: 0;
    box-shadow: none;
}
`

export const StyledTitle = styledComponents(
  CustomTextAreaWithDelayedSubmission
)`

  &.ant-input, .ant-input:placeholder-shown, .ant-input:hover {
    background: transparent;
    background-color: unset;
    border: unset;
    outline: 0;
  }
  &.ant-input:focus {
    border-color: transparent;
    outline: 0;
    box-shadow: none;
}
  padding-inline: 0px;
  font-family: inherit;
  margin-bottom: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit !important;
`
