import { Card } from 'antd'
import { Container, Row, Col, Text, Spin } from '@qonsoll/react-design'
import { useParams } from 'react-router-dom'
import useDocument from 'hooks/useDocument'
import PropTypes from 'prop-types'

const TagAdvancedView = (props) => {
  const params = useParams()
  const { tagId } = params
  const [tag, loading] = useDocument({ ref: `tags/${tagId}` })

  return (
    <Container>
      {loading ? (
        <Spin />
      ) : (
        <Container>
          <Card>
            <Row>
              <Col h="left">
                <Text fontWeight="bold">Name:</Text>
              </Col>
              <Col h="right">
                <Text>{tag?.name}</Text>
              </Col>
            </Row>
          </Card>
        </Container>
      )}
    </Container>
  )
}

TagAdvancedView.propTypes = {
  tag: PropTypes.object
}

export default TagAdvancedView
