import { Menu, MenuItem } from '@qonsoll/react-design'
import styled from 'styled-components'

const StyledMenu = styled(Menu)`
  padding-right: 8px;
  .ant-menu-item {
    border-radius: 0 0 40px 0 !important;
    padding-left: 20px !important;
  }
`
export const StyledMenuItem = styled(MenuItem)`
  border-radius: var(--ql-border-radius-md);
  font-weight: var(--ql-font-weight-medium);
  display: flex;
  align-items: center;
`

export { StyledMenu }
