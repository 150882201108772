import PropTypes from 'prop-types'
import { Title } from '@qonsoll/react-design'
import { Grid } from 'antd'
import { useMemo } from 'react'

const { useBreakpoint } = Grid

export const imageStyles = {
  borderRadius: '64px 0 64px 0',
  objectFit: 'cover',
  objectPosition: 'center'
}

export const StyledTitle = (props) => {
  const { children, ...rest } = props
  const { xs, lg } = useBreakpoint()

  const titleLevel = useMemo(() => (xs ? 3 : !lg ? 2 : 1), [xs, lg])

  return (
    <Title {...rest} level={titleLevel}>
      {children}
    </Title>
  )
}

StyledTitle.propTypes = {
  children: PropTypes.any
}
