import { PageWrapper, Container, Button } from '@qonsoll/react-design'
import { PostAdvancedView } from 'domains/Post/components'
import { useTranslations } from 'contexts/Translation'
import { useHistory } from 'react-router-dom'
import useDocument from 'hooks/useDocument'
import { useParams } from 'react-router-dom'
import { HeaderBreadcrumbs } from 'components'
import { useSharePost } from 'domains/Post/hooks'

export default function PostShow() {
  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()
  const history = useHistory()
  const params = useParams()
  const { postId } = params
  const sharePost = useSharePost()

  // [DATA FETCH]
  const [post, loading] = useDocument({ ref: `posts/${postId}` })
  const {
    previewImageUrl,
    author,
    publishingDate,
    title,
    description,
    isDraft = true,
    path
  } = post || {}

  const handleShare = () => {
    sharePost(path)
  }

  return (
    <Container bounded>
      <PageWrapper
        onBack={() => history?.goBack()}
        breadcrumbs={<HeaderBreadcrumbs />}
        action={
          <Button
            disabled={isDraft || !path}
            onClick={handleShare}
            type="primary"
          >
            {t('Share post')}
          </Button>
        }
      >
        <PostAdvancedView
          loading={loading}
          previewImageUrl={previewImageUrl}
          author={author}
          publishingDate={publishingDate}
          title={title}
          description={description}
        />
      </PageWrapper>
    </Container>
  )
}
