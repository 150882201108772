import { Img, Text, Title } from '@qonsoll/react-design'
import styled from 'styled-components'

export const StyledDescription = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-all;
  ${({ isHorizontalLayout }) =>
    isHorizontalLayout
      ? `-webkit-line-clamp: 2; height: 48px`
      : `-webkit-line-clamp: 4; height: 96px`}
`
export const StyledTitle = styled(Title)`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 22px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  ${({ isHorizontalLayout }) =>
    isHorizontalLayout
      ? `-webkit-line-clamp: 2; height: 64px`
      : `-webkit-line-clamp: 4; height: 128px`}
`

export const StyledImg = styled(Img)`
  object-fit: cover;
  object-position: center;
  width: 100%;
  ${({ isHorizontalLayout }) =>
    isHorizontalLayout ? `height: 100%` : ` height: 242px`}
`
