import { TextArea } from '@qonsoll/react-design'
import { max } from 'lodash'

import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'

const CustomInputWithDelayedSubmission = (props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { onChange, value, onUrlPaste, ...rest } = props

  // [COMPONENT STATE]
  const [localValue, setValue] = useState('')

  // [ADDITIONAL HOOKS]
  const timeoutRef = useRef(null)

  // [FUNCTIONS]
  const extractUrls = (text) => {
    const regex = /(https?:\/\/[^\s]+)/g
    return text?.match(regex) || []
  }
  const submitChanges = useCallback((value) => onChange?.(value), [onChange])
  const handleChange = (e) => {
    const urls = extractUrls(e.target.value)
    if (urls.length) {
      onUrlPaste(urls)
      return
    }
    if (timeoutRef?.current) clearTimeout(timeoutRef.current)
    const _value = e.target.value?.replace(/\s+/g, ' ')
    setValue(_value)
    timeoutRef.current = setTimeout(() => submitChanges(_value), [1000])
  }

  // [COMPUTED PROPERTIES]
  const rowsComputed = useMemo(
    () => (localValue ? max([(localValue.length / 50).toFixed(0), 1]) : 1),
    [localValue]
  )

  // [LIFECYCLE]
  useEffect(() => setValue(value), [value]) // update
  useEffect(() => {
    return () => {
      if (timeoutRef?.current) clearTimeout(timeoutRef.current)
    }
  }, []) // unmount

  return (
    <TextArea
      ref={ref}
      rows={rowsComputed}
      value={localValue}
      onChange={handleChange}
      {...rest}
    />
  )
}

export default forwardRef(CustomInputWithDelayedSubmission)
