import CategorySimpleForm from '../CategorySimpleForm'
import { Form } from 'antd'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'
import { Button, Row } from '@qonsoll/react-design'
import { useCreateCategory } from '../../hooks'

const CategoryAdvancedForm = (props) => {
  const { t } = useTranslations()
  const history = useHistory()
  const createCategory = useCreateCategory()
  const [form] = Form.useForm()

  const onFinish = async () => {
    const { name } = form.getFieldsValue()
    createCategory(name, () => history.goBack())
  }

  const onReset = () => {
    form.resetFields()
    history.push('/categories')
  }

  return (
    <Form
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault()
          onFinish()
        }
      }}
      onFinish={onFinish}
    >
      <CategorySimpleForm showTitle={false} form={form} />
      <Row justifyContent="flex-end">
        <Button htmlType="button" onClick={onReset}>
          {t('Cancel')}
        </Button>
        <Button marginLeft="24px" type="primary" onClick={onFinish}>
          {t('Submit')}
        </Button>
      </Row>
    </Form>
  )
}

export default CategoryAdvancedForm
