import { useCallback } from 'react'
import { useGetUserName } from 'domains/User/hooks'
import { createDocument } from 'services/firestore'
import { DEFAULT_GRADIENT_IMAGE_URL } from 'services/storage/defaultFiles'
import { message } from 'antd'

const useCreatePost = () => {
  const author = useGetUserName()

  const createPost = useCallback(async () => {
    try {
      const { id } = await createDocument('posts', {
        isDraft: true,
        author: author || null,
        title: '',
        description: '',
        tags: [],
        categoryId: null,
        previewImageUrl: DEFAULT_GRADIENT_IMAGE_URL,
        publishingDate: null,
        metaDescription: '',
        path: null,
        descriptionPreview: ''
      })

      return id
    } catch (e) {
      message.error(e.message)
      return null
    }
  }, [author])

  return createPost
}

export default useCreatePost
