import { LeftSquareOutlined } from '@ant-design/icons'
import { Dropdown, Menu, MenuItem } from '@qonsoll/react-design'
import { RemoveMenuItem } from 'components'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const PostCardDropdown = (props) => {
  const { children, onApply, onRemove } = props

  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  return (
    <Dropdown
      trigger={['contextMenu']}
      overlay={
        <Menu>
          <MenuItem
            key="preview"
            icon={<LeftSquareOutlined />}
            onClick={(event) => {
              event.domEvent.stopPropagation()
              onApply?.()
            }}
          >
            {t('Apply')}
          </MenuItem>
          <RemoveMenuItem
            onClick={(event) => {
              event.domEvent.stopPropagation()
              onRemove?.()
            }}
          />
        </Menu>
      }
    >
      {children}
    </Dropdown>
  )
}

PostCardDropdown.propTypes = {
  children: PropTypes.any,
  onApply: PropTypes.func,
  onRemove: PropTypes.func
}

export default PostCardDropdown
