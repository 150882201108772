import { LogoutOutlined } from '@ant-design/icons' /*, UserOutlined */

import { Account } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import firebase from 'firebase/compat/app'
// import { useHistory } from 'react-router-dom'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

const AccountMenu = (props) => {
  const {
    // id,
    avatar,
    displayName,
    email,
    asideLeftCollapsed
  } = props
  const { t } = useTranslations()
  // const history = useHistory()
  const menu = useMemo(
    () => [
      // {
      //   type: 'item',
      //   text: t('Profile'),
      //   icon: <UserOutlined />,
      //   danger: false,
      //   disabled: false,
      //   onClick: () => {
      //     history.push(`/users/${id}`)
      //   }
      // },
      {
        type: 'item',
        text: t('Log out'),
        icon: <LogoutOutlined />,
        danger: true,
        disabled: false,
        onClick: () => firebase.auth().signOut()
      }
    ],
    [t]
  )
  const titleComputed = useMemo(
    () => displayName || t('No name'),
    [displayName, t]
  )
  const captionComputed = useMemo(() => email || t('No email'), [email, t])

  return (
    <Account
      color="white"
      avatar={avatar}
      title={titleComputed}
      caption={captionComputed}
      menu={menu}
      short={asideLeftCollapsed}
      isEllipsis
      menuPlacement="topRight"
    />
  )
}

AccountMenu.propTypes = {
  id: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  displayName: PropTypes.string,
  email: PropTypes.string,
  asideLeftCollapsed: PropTypes.bool
}

export default AccountMenu
