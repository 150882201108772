import { collection, orderBy, onSnapshot, query } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import firestore from 'services/firebase/firestore'

const useListenSnapshots = (postId) => {
  const [snapshots, setSnapshots] = useState([])

  useEffect(() => {
    if (postId) {
      const snapshotsRef = collection(firestore, 'posts', postId, `snapshots`)
      const q = query(snapshotsRef, orderBy('_createdAt', 'desc'))
      const unsubscribe = onSnapshot(q, (snap) => {
        const data = snap.docs?.map((doc) => doc.data())
        setSnapshots(data)
      })
      return () => unsubscribe()
    }
  }, [postId])

  return [snapshots]
}

export default useListenSnapshots
