import { useCallback, useEffect, useRef } from 'react'
import { collection, getDocs, limit, orderBy, query } from 'firebase/firestore'
import firestore from 'services/firebase/firestore'
import { getDocument } from 'services/firestore'
import _ from 'lodash'
import { createDocument } from 'services/firestore'
import moment from 'moment'

const MINUTES_INTERVAL = 5
const INTERVAL = MINUTES_INTERVAL * 60 * 1000

const useSnapshotService = (postId) => {
  const timeoutRef = useRef(null)

  const fetchLastSnapshot = useCallback(async (id) => {
    const q = query(
      collection(firestore, `posts/${id}/snapshots`),
      orderBy('_createdAt', 'desc'),
      limit(1)
    )
    const snapshot = await getDocs(q)
    const _lastSnapshot = snapshot?.docs?.[0]?.data() || null
    return _lastSnapshot
  }, [])

  const createSnapshot = useCallback(async (id, data) => {
    const name = `Snapshot ${moment().format('YYYY-MMM-DD, HH:mm:ss')}`
    await createDocument(`posts/${id}/snapshots`, { post: data, name })
  }, [])

  // Compare current post state with last snapshot
  const compareSnapshots = useCallback(
    async (id) => {
      clearTimeout(timeoutRef.current)
      // Fetch current post state
      const post = await getDocument('posts', id)

      if (post) {
        // Fetch last snapshot
        const snapshot = await fetchLastSnapshot(id)

        const postDataToCompare = {
          title: post.title,
          metaDescription: post.metaDescription,
          description: post.description,
          author: post.author,
          categoryId: post.categoryId,
          previewImageUrl: post.previewImageUrl,
          descriptionPreview: post.descriptionPreview
        }
        // If different, create new snapshot
        const isSame =
          snapshot &&
          _.isEqual(snapshot?.post?.tags || [], post.tags) &&
          _.isEmpty(
            ..._.difference(
              _.values(postDataToCompare),
              _.values(snapshot?.post || {})
            )
          )

        const start = snapshot && moment.unix(snapshot._createdAt.seconds)
        const end = moment()
        const diffInMInutes =
          start && post && moment.duration(end.diff(start)).asMinutes()

        if (!isSame && (!diffInMInutes || diffInMInutes > MINUTES_INTERVAL))
          await createSnapshot(id, { ...postDataToCompare, tags: post.tags })

        timeoutRef.current = setTimeout(() => compareSnapshots(id), INTERVAL)
      }
    },
    [fetchLastSnapshot, createSnapshot]
  )

  useEffect(
    () => postId && compareSnapshots(postId),
    [compareSnapshots, postId]
  )
}

export default useSnapshotService
