import { Input } from 'antd'

import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'

const CustomInputWithDelayedSubmission = (props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { onChange, value, ...rest } = props

  // [COMPONENT STATE]
  const [localValue, setValue] = useState('')

  // [ADDITIONAL HOOKS]
  const timeoutRef = useRef(null)

  // [FUNCTIONS]
  const submitChanges = useCallback((value) => onChange?.(value), [onChange])
  const handleChange = (e) => {
    if (timeoutRef?.current) clearTimeout(timeoutRef.current)

    const value = e.target.value
    setValue(value)

    timeoutRef.current = setTimeout(() => submitChanges(value), [1000])
  }

  // [LIFECYCLE]
  useEffect(() => setValue(value), [value]) // update
  useEffect(() => {
    return () => {
      if (timeoutRef?.current) clearTimeout(timeoutRef.current)
    }
  }, []) // unmount

  return (
    <Input ref={ref} value={localValue} onChange={handleChange} {...rest} />
  )
}

export default forwardRef(CustomInputWithDelayedSubmission)
