import firebase from 'firebase/compat/app'
import { useCallback } from 'react'
import { useTranslations } from 'contexts/Translation'

const useCheckPostTitleAvailability = () => {
  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()

  const checkTitle = useCallback(
    async (title, path) => {
      if (!title) throw new Error(t('Title is required'))

      const publishedPostsSnapshot = await firebase
        .firestore()
        .collection('posts')
        .where('isDraft', '==', false)
        .get()

      const publishedPostsPaths = publishedPostsSnapshot.docs.map(
        (doc) => doc.data().path
      )
      const publishedPostsTitles = publishedPostsSnapshot.docs.map(
        (doc) => doc.data().title
      )

      if (
        publishedPostsTitles.includes(title) ||
        publishedPostsPaths.includes(path)
      ) {
        throw new Error(
          `${t('You can not publish a post')}. ${t(
            'This title is already used!'
          )}`
        )
      }
    },
    [t]
  )

  return checkTitle
}

export default useCheckPostTitleAvailability
