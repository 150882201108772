import { Checkbox } from 'antd'
import PropTypes from 'prop-types'
import { useTags } from 'domains/Tag/hooks'
import { useSubfilterActions } from 'hooks'
import { StyledDetails } from '../PostFilter.styles'
import { useTranslations } from 'contexts/Translation'

const TagFilter = ({ filterData, setFilterData }) => {
  const [tags] = useTags()

  const { onToggleDetails, onChange, checkIsEnabled } = useSubfilterActions({
    filterData,
    setFilterData,
    fieldName: 'tags',
    operand: 'array-contains'
  })
  const { t } = useTranslations()

  return (
    <StyledDetails
      open={localStorage.getItem(`tag-details-visibility`) === 'opened'}
    >
      <summary onClick={() => onToggleDetails('tag')}>{t('Tag')}</summary>
      {tags ? (
        tags?.map((tag, index) => (
          <div key={`status-${index}`}>
            <Checkbox
              key={`status-${index}`}
              checked={checkIsEnabled(tag)}
              onChange={() => onChange(tag)}
            >
              {tag?.name}
            </Checkbox>
          </div>
        ))
      ) : (
        <></>
      )}
    </StyledDetails>
  )
}

TagFilter.propTypes = {
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default TagFilter
