import { Avatar } from 'antd'
import { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import { usePostFormOnlineUsers } from 'domains/Post/components/PostAdvancedForm/hooks'
import { Box } from '@qonsoll/react-design'

const OnlineUsers = (props) => {
  const { postId, ...rest } = props

  // [ADDITIONAL HOOKS]
  const onlineUsers = usePostFormOnlineUsers(postId)

  // [COMPUTED PROPERTIES]
  const activeUsers = useMemo(
    () => Object.values(onlineUsers || {}),
    [onlineUsers]
  )

  return (
    <Box {...rest}>
      <Avatar.Group maxCount={4} size="large">
        {activeUsers?.map(({ avatarUrl, userId }) => (
          <Avatar key={userId} src={avatarUrl} />
        ))}
      </Avatar.Group>
    </Box>
  )
}

OnlineUsers.propTypes = {
  postId: PropTypes.string
}

export default memo(OnlineUsers)
