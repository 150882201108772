import { Container } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { Select } from 'antd'
import { useTranslations } from 'contexts/Translation'
import { memo, useMemo } from 'react'
import { StyledSelect } from './TagListWithCreate.styled'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import firebase from 'firebase/compat/app'
import { capitalizeText, normalizeText, transformTextIntoPath } from 'helpers'

const { Option } = Select

const TagListWithCreate = (props) => {
  const { tags, setTags, placeholder } = props

  // [ADDITIONAL PROPS]
  const { t } = useTranslations()

  // [DATA FETCH]
  const [allTags] = useCollectionData(
    firebase.firestore().collection('tags').orderBy('name')
  )

  // [COMPUTED PROPERTIES]
  const possibleOptions = useMemo(
    () =>
      tags
        ? [
            ...(new Map(
              allTags?.concat(tags)?.map((item) => [item?.['name'], item])
            )?.values() || [])
          ]
        : allTags,
    [allTags, tags]
  )
  const tagsNames = useMemo(() => tags?.map((item) => item?.name), [tags])

  // [FUNCTIONS]
  const arrayContainsItem = (item) => {
    return possibleOptions.some(
      (tag) => tag.name?.toLowerCase() === item?.toLowerCase()
    )
  }
  const onChange = (value) => {
    const _tags = value.map((item) => {
      if (!arrayContainsItem(item)) {
        const nameComputed = normalizeText(capitalizeText(item))
        const path = transformTextIntoPath(nameComputed)
        const newTag = { name: nameComputed, path }

        return newTag
      }

      return possibleOptions.find(
        (tag) => tag.name?.toLowerCase() === item?.toLowerCase()
      )
    })

    setTags([...new Set(_tags)])
  }

  return (
    <Container>
      <StyledSelect
        onChange={onChange}
        mode="tags"
        placeholder={placeholder || t('Enter tags')}
        value={tagsNames}
      >
        {Array.from(possibleOptions).map((tag) => (
          <Option key={tag?._id} value={tag?.name}>
            {tag?.name}
          </Option>
        ))}
      </StyledSelect>
    </Container>
  )
}

TagListWithCreate.propTypes = {
  placeholder: PropTypes.string,
  setTags: PropTypes.func,
  tags: PropTypes.arrayOf(PropTypes.object)
}

export default memo(TagListWithCreate)
