import { Select } from 'antd'
import styledComponents from 'styled-components'

export const StyledSelect = styledComponents(Select)`
  width: 100%;

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent;
    background-color: unset;
    border: unset !important;
    border-color: unset;
  }
  
  &.ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    box-shadow: none !important;
    background: transparent;
    border: unset;
  }

  &.ant-select-multiple .ant-select-selection-item {
    border-radius: 8px;
  }
`
