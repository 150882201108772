import {
  PageWrapper,
  Container,
  Row,
  Col,
  Spin,
  Button
} from '@qonsoll/react-design'
import { TagList, TagSortMenu } from 'domains/Tag/components'
import { useTranslations } from 'contexts/Translation'
import { HeaderBreadcrumbs } from 'components'
import { useTags } from 'domains/Tag/hooks'
import { useHistory } from 'react-router-dom'
import {
  MenuOutlined,
  OrderedListOutlined,
  AppstoreOutlined
} from '@ant-design/icons'
import { useMemo } from 'react'
import { Popover } from 'antd'
import { useStateWithStorage } from 'hooks'

export default function TagsAll() {
  // [ADDITIONAL HOOKS]
  const { t } = useTranslations()
  const history = useHistory()

  // [COMPONENT STATE]
  const [listView, setListView] = useStateWithStorage(false, 'tagsListView')
  const [filterData, setFilterData] = useStateWithStorage({}, 'tagsFilterData')

  // [DATA FETCH]
  const [tags, loading] = useTags(filterData)

  // [COMPUTED PROPERTIES]
  const headingProps = useMemo(
    () => ({ title: t('Tags'), titleSize: 2, textAlign: 'left' }),
    [t]
  )

  return (
    <Container bounded>
      <PageWrapper
        alignMiddle
        action={
          <>
            <Popover
              placement="bottomLeft"
              trigger="click"
              content={
                <TagSortMenu
                  filteredData={filterData}
                  setFilteredData={setFilterData}
                />
              }
            >
              <Button icon={<OrderedListOutlined />} mr={2} />
            </Popover>
            <Button
              onClick={() => setListView(!listView)}
              icon={listView ? <AppstoreOutlined /> : <MenuOutlined />}
            />
          </>
        }
        onBack={() => history?.goBack()}
        breadcrumbs={<HeaderBreadcrumbs />}
        headingProps={headingProps}
      >
        <Row noOuterGutters>
          {loading ? (
            <Col>
              <Spin />
            </Col>
          ) : (
            <Col>
              <TagList listView={listView} tags={tags} />
            </Col>
          )}
        </Row>
      </PageWrapper>
    </Container>
  )
}
