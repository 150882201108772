import { Divider, Title } from '@qonsoll/react-design'
import { TagFilter, CategoryFilter, DraftFilter } from './components'
import { StyledFilter, StyledHeader } from './PostFilter.styles'
import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'

const PostFilter = (props) => {
  const { filterVisibility, filterData, setFilterData } = props
  const { t } = useTranslations()

  return filterVisibility ? (
    <>
      <StyledHeader>
        <Title mb="8px" level={4}>
          {t('Filter')}
        </Title>
      </StyledHeader>
      <StyledFilter maxHeight={['calc(100vh - 225px)', 'calc(100vh - 205px)']}>
        <DraftFilter filterData={filterData} setFilterData={setFilterData} />
        <Divider my="8px" />
        <TagFilter filterData={filterData} setFilterData={setFilterData} />
        <Divider my="8px" />
        <CategoryFilter filterData={filterData} setFilterData={setFilterData} />
      </StyledFilter>
    </>
  ) : null
}

PostFilter.propTypes = {
  filterVisibility: PropTypes.bool,
  setFilterVisibility: PropTypes.func,
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default PostFilter
