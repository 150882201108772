import useCollection from 'hooks/useCollection'

export default function useTags(props) {
  const ref = 'tags'

  const [value, loading, error, next, loadingMore, loadMoreAvailable] =
    useCollection({ ref, ...props })

  return [value, loading, error, next, loadingMore, loadMoreAvailable]
}
