import { doc, onSnapshot } from 'firebase/firestore'
import { useEffect, useRef, useState } from 'react'
import firestore from 'services/firebase/firestore'

const useListenDocument = (ref) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const subscriptionRef = useRef(null)

  useEffect(() => {
    if (ref) {
      try {
        setLoading(true)

        const docRef = doc(firestore, ref)
        subscriptionRef.current = onSnapshot(docRef, (querySnapshot) => {
          const data = querySnapshot.data()
          setData(data)
          setLoading(false)
        })
      } catch (error) {
        setError(error)
      }
    }
  }, [ref])

  useEffect(() => {
    return () => {
      const unsubscribe = subscriptionRef.current
      unsubscribe?.()
    }
  }, [])

  return [data, loading, error]
}

export default useListenDocument
